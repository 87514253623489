import React from 'react';
import { CFormText, CLabel } from '@coreui/react';
import MandatoryIndicator from './MandatoryIndicator';
import * as appConstants from './../../AppConstants';
import { KeyboardDatePicker, KeyboardTimePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as dateConfigs from './../../shared/configs/DateConfigs';


var DialogProps = {
    backgroundColor: "red"
};

const KeyboardDatePickers = (props) => (
    <div>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                style={{ width: '100%' }}
                id={appConstants.INPUT_OUTLINED_LABEL.id}
                // variant={"inline"} // static, dialog(default), inline
                //variant={"inline" + appConstants.INPUT_FLOAT_LABEL.variant}
                // disableToolbar
                autoOk={true}
                // clearable
                //maskChar
                mask
                // orientation={"landscape"} // potrait(default), landscape 
                format={props.format}
                name={props.name}
                margin="normal"
                inputVariant={appConstants.INPUT_OUTLINED_LABEL.variant}
                label={(props.manadatorySymbol) ? (<MandatoryIndicator title={props.title} />) : props.title}
                disabled={(props.disabled) ? true : false}
                value={props.content}
                onChange={props.controlFunc}
                disablePast={props?.disable ? props.disable : false}
                disableFuture={props.future}
                shouldDisableDate={props.weekend}
                //highLight ={props.hightLight}
                onMonthChange={props.onMonthChange}
                renderDay={props.renderDay}
                minDate={(props?.minDate) ? (props?.minDate) : dateConfigs.PICKER_DEFAULT_DATE}
                views={!(props?.views) ? ['year', 'month', 'date'] : props.views}

            />
        </MuiPickersUtilsProvider>
    </div>
);

export default KeyboardDatePickers;