export const STATUS = [
    { 'label': 'Active', 'value': 'Active' },
    { 'label': 'Inactive', 'value': 'Inactive' },
];

export const LANGUAGES = [
    'Kannada', 'Hindi', 'Telugu', 'Tamil', 'Malayalam', 'Urdu', 'Tulu', 'Konkani', 'Bengali', 'Oriya',
    'Assamese', 'Kashmiri', 'Lambani', 'Marathi',
    'Gujarathi', 'Bangla', 'English', 'Other Language'
];

export const UNIT = [
    { 'label': 'Unit1', 'value': 1 },
    { 'label': 'Unit2', 'value': 2 },
    { 'label': 'Unit3', 'value': 3 },
    { 'label': 'Unit4', 'value': 4 }
];

export const DAYS = [
    { 'label': 'Monday', 'value': 'Monday' },
    { 'label': 'Tuesday', 'value': 'Tuesday' },
    { 'label': 'Wednesday', 'value': 'Wednesday' },
    { 'label': 'Thursday', 'value': 'Thursday' },
    { 'label': 'Friday', 'value': 'Friday' },
    { 'label': 'Saturday', 'value': 'Saturday' }
];

export const ROLE_CODES = {
    "administrator_role_code": "ADMINISTRATOR"
};

export const SALUTATION = [
    { 'label': 'Mr.', 'value': 'Mr.' },
    { 'label': 'M/s.', 'value': 'M/s.' },
    { 'label': 'Dr.', 'value': 'Dr.' }
];

export const GENDER = [
    {'label':'Male', 'value':'Male'},
    {'label':'Female', 'value':'Female'},
    {'label':'Transgender', 'value':'Transgender'}
];

export const MODE_OF_COURSE = [
    {'label': 'Regular', 'value': 'Regular'},
    {'label':'Distance', 'value': 'Distance'}
]