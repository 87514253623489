import { useState, useEffect, useRef } from 'react'
import { CSVLink } from 'react-csv'
const DownloadToCsv = ({ data = [], headers = [], fileName }) => {
    const downloadLink = useRef(null)
    const [download, setDownload] = useState(true)

    useEffect(() => {
        if (download) {
            console.log("downloading csv file...", fileName)
            downloadCsv()
        }
    }, [])

    const downloadCsv = () => {
        setDownload(false)
        downloadLink.current.click()
    }

    return (
        <CSVLink data={data} filename={fileName} headers={headers?.length > 0 ? headers : null}>
            <button ref={downloadLink} />
        </CSVLink>
    )
}
export default DownloadToCsv;