import React from 'react';
// import { CFormText, CLabel } from '@coreui/react';
import { TextField } from '@material-ui/core';
import MandatoryIndicator from './MandatoryIndicator';
import * as appConstants from './../../AppConstants';
import * as lengthValidator from './../../utils/LengthValidator';

const SingleTextInput = (props) => (
    <div>
        <TextField
            // color="secondary"
            // id={appConstants.INPUT_OUTLINED_LABEL.id}
            style={{ width: '100%', marginTop: '10px' }}
            variant={(props.variant) ? props.variant : appConstants.INPUT_OUTLINED_LABEL.variant}
            label={(props.manadatorySymbol) ? (<MandatoryIndicator title={props.title} />) : props.title}
            name={props.name}
            type={props.inputType}
            value={props.content}
            onChange={props.controlFunc}
            onKeyDown={props.controlKeyDownFunc}
            // onBlur={props.controlBlurFunc} 
            onBlur={props.controlBlurFunc ? props.controlBlurFunc : null}
            placeholder={props.placeholder}
            multiline={(props.multiline) ? true : false}
            minRows={(props.rows) ? props.rows : 0}
            disabled={(props.disabled) ? true : false}
            cols={(props.rows) ? props.rows : 0}
            max={props.maximum}
            min={props.minimum}
            inputMode={props.inputmode}
            className={props.class ? props.calss : ''}
            InputProps={{
                inputProps: {
                    max: props.maximum, min: props.minimum, maxLength: props.maxLength, readOnly: props.readOnly
                }
            }} />
        {/* <CFormText>{props?.helpText}</CFormText> */}
        {/* {lengthValidator.showmessage(props?.content, props?.length)} */}
    </div>
);

export default SingleTextInput;