import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from 'react-bootstrap';
import * as appConstants from './../../../AppConstants';
import UsersService from './../../../api/UsersService';
import Box from '@mui/material/Box';
import GlobalState from './../../../shared/GlobalState';
import SimpleReactValidator from 'simple-react-validator';
import SingleTextInput from './../../../components/form-components/SingleTextInput';
import RadioButtonList from './../../../components/form-components/RadioButtonList';
import Button from './../../../components/form-components/Button';
import KeyboardDatePickers from './../../../components/form-components/KeyboardDatePicker';
import { toast } from 'react-toastify';
import Toaster from './../../../components/form-components/Toaster';
import { useNavigate } from "react-router-dom";
import { strengthColor, strengthIndicator } from '../../../utils/password-strength';
import PasswordInput from "../../../components/form-components/PasswordInput";
import SingleCheckbox from '../../../components/form-components/SingleCheckbox'
import * as UserManagementConfigs from './../../../shared/UserManagementConfig';
const CreateUser = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [strength, setStrength] = useState(0);
    const [state, setState] = useContext(GlobalState);
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [user, setUser] = useState({});
    const [level, setLevel] = useState();
    const [userParams, setUserParams] = useState({
        confirmPass: null
    });

    useEffect(() => {
        console.log("User Create page loaded. Msg. from UseEffect")
        getUserById();
    }, []);


    const getUserById = () => {
        if (params.user_id > 0) {
            UsersService.getUserById(params.user_id, state.authHeader).then(resp => {
                if (resp.data.id) {
                    // log.info('Setting User Id to local state variable', SharedService.getStateInfo(state))
                    setUserParams({ ...userParams, confirmPass: resp.data.password });
                    setUser(resp.data)
                    // log.info('User Data retrieved Successfully', SharedService.getStateInfo(state))
                }
            });
        }
        else {
            let userType = params.user_type;
            setUser({ ...user, user_type: userType });
        }
        return;
    }

    const saveOrUpdateUser = (data, e) => {
        var md5 = require('md5');
        if (params.user_id) {
            e.preventDefault();
            UsersService.updateUser(data, params.user_id, state.authHeader).then(res => {
                // log.debug(res.data.id + ' is been Updated: ', SharedService.getStateInfo(state))
                setUser({});
                navigate('/users/list', { 'replace': true });
            });
        }
        else {

            if (data.password !== userParams.confirmPass) {
                alert("password not matching, please re-enter again");
                return false;
            } else {
                let encryptPwd = md5(data.password)
                data.password = encryptPwd
                data.status = appConstants.USER_STATUS.active;
                e.preventDefault();
                UsersService.createUser(data, state.authHeader).then(res => {
                    // log.info('User data Saved Successfully :', SharedService.getStateInfo(state))
                    console.log(res.data.data)
                    console.log(res.data.data.user_type)
                    if (res?.data?.code === 200) {
                        toast.success('User created successfully');
                        navigate('/users/list');
                    }
                    if (res?.data?.code === 409) {
                        toast.warning(res?.data?.message);
                    }
                });
            }
        }
    }

    const validateForm = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            // log.debug('checks whether all the fields entered are valid', SharedService.getStateInfo(state))
            saveOrUpdateUser(user, e)
        } else {
            validator.current.showMessages();
            forceUpdate(1)
        }
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        console.log(validator.current.getErrorMessages())
        if (name === 'confirmPass') {
            setUserParams({ ...userParams, [name]: value });
        }
        else if (name === 'phone_number') {
            if (validator.current.check(value, 'numeric|max:10'))
                setUser({ ...user, [name]: value });
        }
        else if (name === 'first_name' || name === 'last_name') {
            if (validator.current.check(value, 'alpha_space'))
                setUser({ ...user, [name]: value });
        }
        else {
            if (type === 'checkbox') {
                console.log("in checkbox", e.target.checked, name)
                user[name] = e.target.checked ? value : ''
                setUser({ ...user, [name]: e.target.checked ? value : '' });
            } else {
                setUser({ ...user, [name]: value });
            }
        }
    }

    const gotoUserList = () => {
        navigate('/users/list')
    }
    const changePassword = (e) => {
        if (e.target.name === 'password') {
            const temp = strengthIndicator(e.target.value);
            setStrength(temp);
            setLevel(strengthColor(temp));
        }
    };

    return (
        <>
            <Toaster position='bottom-left' duration='5000' />
            <Container>
                <Row>
                    <Col align="center" xs="12" sm="12" md="12" lg="12" xl="12"></Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <form onSubmit={validateForm} className="form-horizontal">
                            <Card border="primary">
                                <Card.Header align="center">
                                    <h2 className="card-header-h2">
                                        {user?.id ? "Update " : "Create "}user
                                    </h2>
                                </Card.Header>
                                <Card.Body>

                                    <Row className="formMargins">
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="first_name"
                                                title="First name"
                                                inputType="text"
                                                content={(user.first_name) ? (user.first_name) : ''}
                                                placeholder="First name"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('first_name', user.first_name, 'required|alpha_space')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="last_name"
                                                title="Last name"
                                                inputType="text"
                                                content={(user.last_name) ? (user.last_name) : ''}
                                                placeholder="Last name"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('last_name', user.last_name, 'required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="username"
                                                title="Username"
                                                inputType="text"
                                                content={(user.username) ? (user.username) : ''}
                                                placeholder="Username"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('username', user.username, 'required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="email"
                                                title="Email"
                                                inputType="email"
                                                content={(user.email) ? (user.email) : ''}
                                                placeholder="Email"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('email', user.email, 'email|required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="phone_number"
                                                title="Phone number"
                                                inputType="text"
                                                content={(user.phone_number) ? (user.phone_number) : ''}
                                                placeholder="Phone number"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('phone_number', user.phone_number, 'required')}
                                        </Col>


                                        {/* </Row>
                                    <Row className="formMargins"> */}
                                        {!params.user_id &&
                                            <>
                                                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <PasswordInput name="password"
                                                        title="Password"
                                                        content={(user.password) ? (user.password) : ''}
                                                        manadatorySymbol={true}
                                                        controlFunc={handleChange}
                                                        passwordChangeFunc={changePassword} />
                                                    {validator.current.message('password', user.password, 'required')}
                                                    {strength !== 0 && (
                                                        <Row>
                                                            <Col>
                                                                <Box
                                                                    style={{ backgroundColor: level?.color }}
                                                                    sx={{ width: 85, height: 8, borderRadius: '7px' }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <h5 style={{}}>{level?.label}</h5>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </Col>
                                                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <PasswordInput name="confirmPass"
                                                        title="Confirm Password"
                                                        content={(userParams.confirmPass) ? (userParams.confirmPass) : ''}
                                                        manadatorySymbol={true}
                                                        controlFunc={handleChange}
                                                        passwordChangeFunc={changePassword} />
                                                    {validator.current.message('confirmPass', userParams.confirmPass, 'required')}
                                                </Col>
                                            </>}
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleCheckbox
                                                name='role_code'
                                                controlFunc={handleChange}
                                                content={user?.role_code ? user?.role_code : ''}
                                                title='Is Administrator'
                                                value={UserManagementConfigs.ROLE_CODES.administrator_role_code}
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer align="center">
                                    <Button label={"Cancel"}
                                        color={appConstants.DELETE_BTN_COLOR}
                                        handleClick={gotoUserList} />
                                    <Button label={user?.id ? "Update & Continue" : "Save & Continue"}
                                        buttonStyle={{ margin: '0px 5px' }}
                                        color={appConstants.SUBMIT_BTN_COLOR}
                                        btnType={'submit'}
                                    />
                                </Card.Footer>
                            </Card>
                        </form >
                    </Col>
                    <Col align="center" xs="0" sm="1" md="2" lg="3" xl="3"></Col>
                </Row>
            </Container >
        </>
    )
}

export default CreateUser;