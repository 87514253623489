import { useContext, useEffect, useState } from "react";
import PatientAssessmentService from "../../api/PatientAssessmentService";
import GlobalState from "../../shared/GlobalState";
import { Card, Container, Row, Col, Accordion } from 'react-bootstrap';
import { CButtonGroup, CButton } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckDouble, faCheckToSlot, faCircleExclamation, faClock, faExclamation, faExclamationCircle, faFileVideo, faListCheck, faRecordVinyl, faUserCheck, faVideo, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import * as appConstants from '../../AppConstants';
import * as sharedService from '../../shared/SharedService';

const VideoAssessmentList = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [videoList, setVideoList] = useState();
    const [assessment, setAssessment] = useState({})
    const [state, setState] = useContext(GlobalState)

    useEffect(() => {
        console.log("video list page loaded")
        getPatientAssessmentById()
    }, [])

    const getAllVideosWithSet = (set_code) => {
        PatientAssessmentService.getAllVideosBySets(params?.assessment_id, state?.authHeader, set_code).then(resp => {
            setVideoList(resp?.data);
        })
    }

    const getPatientAssessmentById = () => {
        PatientAssessmentService.getPatientAssessmentById(params?.assessment_id, state?.authHeader).then(resp => {
            if (resp?.data?.set_code) {
                getAllVideosWithSet(resp?.data?.set_code)
            } else {
                getAllVideosWithSet('all')
            }
        })
    }
    const updateAssessment = (set) => {
        assessment.set_code = set
        assessment.patient_id = params?.patient_id
        assessment.user = sharedService.getUserObject(state?.loggedUser)
        PatientAssessmentService.updatePatientAssessment(params?.assessment_id, assessment, state?.authHeader).then(resp => {
            console.log("patient assessment updated", resp?.data)
            setState({ ...state, set_code: set })
            // let url = '/patient-video-assessment/';
            // if (set === "A-Kannada")
            //     url += "kannada/";
            // else if (set === "A-English")
            //     url += "english/";
            // else if (set === "A-Hindi")
            //     url += "hindi/";
            // else
            //     return;
            navigate('/patient-video-assessment/' + params.patient_id + '/' + params.assessment_id)
        })
    }
    return (
        <>
            <Container>
                <Accordion >
                    {videoList?.video_sets?.map((item, index) => (
                        <Accordion.Item key={index + item?.id} eventKey={index}>
                            <Accordion.Header>
                                <Row style={{ display: 'contents' }}>
                                    <Col>
                                        <label>{item}</label>
                                    </Col>
                                    <Col align='right'>
                                        <a className="custom-button" onClick={() => { updateAssessment(item) }}>Proceed</a>
                                    </Col>
                                </Row>
                            </Accordion.Header>
                            <Accordion.Body>
                                <Row>
                                    {videoList?.videos_by_set[item]?.map((vidObj, index) => (
                                        <Col lg='6' key={index + '_' + vidObj?.id} className='list-group-item'>
                                            <label>{index + 1}.&nbsp;&nbsp;{vidObj?.video_code}</label>
                                            {vidObj?.patient_score?.final_score &&
                                                <>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faCheck} color={appConstants.SUCCESS_BTN_COLOR} size="lg" />
                                                </>
                                            }
                                        </Col>
                                    ))}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Container>
        </>
    )
}
export default VideoAssessmentList;