import React from "react";
import './Footer.css'

const Footer = () => {
  return (
    <>
      <footer className="app-footer">
        <div className="container">
          <div className="row text-center">
            <p className="m-2">&copy;2022, All Rights Reserved, NIMHANS</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
