import React, { useContext, useEffect, useState } from 'react';
import UsersService from './../../../api/UsersService';
import { Card, Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { CButtonGroup, CButton } from '@coreui/react';
import SingleTextInput from '../../../components/form-components/SingleTextInput';
import Button from './../../../components/form-components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faKey, faPhone, faEnvelope, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';
import * as appConstants from './../../../AppConstants';
import GlobalState from './../../../shared/GlobalState';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@mui/material/Box';



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabspanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index, obj) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const UsersList = () => {

    const theme = useTheme();
    const [state, setState] = useContext(GlobalState);
    const [userList, setUserList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [user, setUser] = useState({ active: state?.loggedUser?.active });
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setState({ ...state, patient: null, assessmentDate: null })
        console.log("User List page loading")
        getAllUsers('Active')
    }, []);

    const getAllUsers = (status) => {
        UsersService.getAllUsersByStatus(status, state.authHeader).then((res) => {
            setUserList(res.data);
        })
    }
    const editUser = (user) => {
        console.log("in user edit")
        navigate('/users/update/' + user.id);
    }

    const handleChange = (e) => {
        user[e.target.name] = e.target.value;
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const handleChangePassword = (e) => {
        setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
    }

    const updateUserPassword = () => {
        var md5 = require('md5');
        let encryptPwd = md5(selectedUser.password);
        selectedUser.password = encryptPwd;
        selectedUser.force_update_password = true;
        console.log(selectedUser)
        UsersService.updateUser(selectedUser, selectedUser.id, state.authHeader).then(res => {
            // log.debug(res.data.id + ' is been Updated: ', SharedService.getStateInfo(state))
            closeChangePasswordModal();
            setSelectedUser({});
        });
    }
    const changePasswordModal = (user) => {
        setSelectedUser(user)
        setShowChangePasswordModal(true)
    }

    const openChangePasswordModal = () => {
        return (
            <Modal
                show={showChangePasswordModal}
                onHide={closeChangePasswordModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedUser.first_name}&nbsp;{selectedUser.middle_name}&nbsp;{selectedUser.last_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs="12" sm="6" md="6" lg="6" xl="6">
                            <SingleTextInput
                                name="password"
                                title="Password"
                                inputType="text"
                                controlFunc={handleChangePassword} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        label="Update"
                        color={appConstants.CANCEL_BTN_COLOR}
                        size="sm"
                        title="Update"
                        handleClick={() => { updateUserPassword() }}
                    />
                </Modal.Footer>

            </Modal>
        );
    }
    const closeChangePasswordModal = () => {
        setShowChangePasswordModal(false);
    }

    const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    const [value, setValue] = useState(0);

    const handleTabChange = (e, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            getAllUsers('Active')
        } else {
            getAllUsers('Inactive')
            // getting inactive users
        }
    }

    const getTabs = () => {
        let tabs = []
        tabList.map((element, index) => {
            tabs.push(
                <Tooltip
                    title={element.label}
                    key={element.value + index}>
                    <Tab label={element.label}
                        aria-label={element.label}
                        {...a11yProps(index)} />
                </Tooltip>)
        });
        return tabs;
    }

    const updateUserStatus = (user, status) => {
        user.status = status;
        UsersService.updateUser(user, user?.id, state?.authHeader).then(resp => {
            getAllUsers(value == 0 ? 'Active' : 'Inactive')
        })
    }

    const getTabPanels = () => {
        let tabPanels = []
        tabList.map((item, index) => {
            tabPanels.push(
                <TabPanel value={value} index={index}
                    dir={theme.direction}
                    key={item.value}>
                    <Row>
                        {userList.map((user, index) => (
                            <Col key={index + 1} xs="12" sm="12" md="12" lg="4" xl="4">
                                <Card style={{ marginBottom: '5px' }} border={value === 0 ? 'info' : 'danger'} className={value === 0 ? 'accent-info' : 'accent-danger'}>
                                    <Card.Header>
                                        <Row>
                                            <Col>
                                                <Card.Title>{user?.first_name + ' ' + user?.last_name}</Card.Title>
                                            </Col>
                                            <Col align='right'>
                                                <CButtonGroup>
                                                    {value === 0 &&
                                                        <CButton
                                                            // className={'block'}
                                                            variant={'outline'}
                                                            color={appConstants.SUBMIT_BTN_COLOR}
                                                            size="sm"
                                                            title='Edit user'
                                                            onClick={() => { editUser(user) }}
                                                        ><FontAwesomeIcon icon={faPencilAlt} /></CButton>
                                                    }
                                                    {value === 0 &&
                                                        <CButton
                                                            variant={'outline'}
                                                            color={appConstants.EDIT_BTN_COLOR}
                                                            size="sm"
                                                            title="Change password"
                                                            onClick={() => changePasswordModal(user)}
                                                        ><FontAwesomeIcon icon={faKey} /></CButton>
                                                    }
                                                    {value == 0 &&
                                                        <CButton
                                                            variant={'outline'}
                                                            color={appConstants.DELETE_BTN_COLOR}
                                                            size="sm"
                                                            title="Deactivate user"
                                                            onClick={() => { updateUserStatus(user, appConstants.USER_STATUS.inactive) }}
                                                        ><FontAwesomeIcon icon={faTrash} /></CButton>
                                                    }
                                                    {value == 1 &&
                                                        <CButton
                                                            variant={'outline'}
                                                            color={appConstants.SAVE_BTN_COLOR}
                                                            size="sm"
                                                            title="Activate user"
                                                            onClick={() => { updateUserStatus(user, appConstants.USER_STATUS.active) }}
                                                        ><FontAwesomeIcon icon={faCheck} /></CButton>
                                                    }

                                                </CButtonGroup>
                                            </Col>
                                        </Row>

                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col xs='12' md='12' lg='12'>
                                                {user?.phone_number &&
                                                    <FontAwesomeIcon icon={faPhone} />}
                                                &nbsp;{user?.phone_number ? user?.phone_number : ''}
                                            </Col>
                                            <Col xs='12' md='12' lg='12'>
                                                {user?.email &&
                                                    <FontAwesomeIcon icon={faEnvelope} />} &nbsp;{user?.email ? user?.email : ''}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                        }
                    </Row>
                </TabPanel >
            )
        });
        return tabPanels;
    }

    const tabList = [
        { 'label': 'Active', 'value': 1 },
        { 'label': 'Inactive', 'value': 2 },
    ];

    return (
        <>
            {
                openChangePasswordModal()
            }
            <Container>
                <Card border="primary" style={{ backgroundColor: '#ebf5ff' }}>
                    <Card.Header>
                        <Row>
                            <Col>
                                <h2 className="card-header-h2 card-list-header">User List</h2>
                            </Col>
                            <Col xs="4" sm="2" md="2" lg="2" xl="2">
                                {/* {(SharedService.checkUserHasPermission(state?.loggedUser?.roles[0]?.permissions, pageCodes.user_create) || state?.loggedUser?.roles[0]?.role_code === userManagementConfigs.ROLE_CODES.administrator_role_code) && */}
                                <Link
                                    className="c-subheader-nav-link card-list-header"
                                    aria-current="page"
                                    to="/users/create"
                                    style={{ float: 'right' }}>
                                    <FontAwesomeIcon icon={faPlus} />&nbsp;New User
                                </Link>
                                {/* } */}
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Tabs
                            value={value}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="scrollable auto tabs example"
                            className={'patientVisitTopTabs'}
                        >
                            {getTabs()}
                        </Tabs>

                        <div className='patientVisitTopTabPanels'>
                            {getTabPanels()}
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default UsersList;