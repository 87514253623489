import moment from 'moment';
import * as DateConfigs from './configs/DateConfigs';

export const subtract = props => {
    return moment(props.date).subtract(props.count, props.term)
}

export const add = props => {
    return moment(props.date).add(props.count, props.term)
}
export const currentDateTime = () => {
    return moment();
}

export const currentMomentDateTime = () => {
    return moment();
}

export const currentDate = (format) => {
    return moment().format(format ? format : DateConfigs.DEFAULT_DATE_FORMAT_UI);
}

export const currentDateTimeCustomFormat = (format) => {
    return moment().format(format);
}
export const formatDate = (date, format) => {
    if (date)
        return moment(date).format(format ? format : DateConfigs.DEFAULT_DATE_FORMAT_UI);
    else
        return;
}
export const getDifferenceBetweenDates = (fromDate, toDate, diffType = 'months') => {
    let diff = 0;
    if (fromDate && toDate) {
        diff = moment(toDate).diff(moment(fromDate), diffType);
    }
    return diff;
}
export const getAgeFromDOB = (date, currentDate, duration) => {
    if (date) {
        return getDifferenceBetweenDates(date, currentDate, duration);
    } else {
        return 0;
    }
}
export const getDOBFromAge = (age, duration) => {
    if (age > 0) {
        return formatDate(subtract({ 'date': moment(), 'count': age, 'term': duration }), DateConfigs.DEFAULT_DATE_FORMAT_BACKEND);
    } else {
        return formatDate(moment(), DateConfigs.DEFAULT_DATE_FORMAT_BACKEND);
    }
}
export const getStartOfWeekOrMonthByDate = (date, term) => {
    if (date)
        return moment(date).startOf(term);
    else
        return moment().startOf(term);
}
export const getEndOfWeekOrMonthByDate = (date, term) => {
    if (date)
        return moment(date).endOf(term);
    else
        return moment().endOf(term);
}
export const getStartAndEndOfWeekOrMonthByDate = (date, term) => {
    return { "start": getStartOfWeekOrMonthByDate(date, term), "end": getEndOfWeekOrMonthByDate(date, term) };
}
export const compareTwoDatesEqual = (date1, date2) => {
    return moment(date1).isSame(moment(date2));
}

export const formatDatetimeToISOString = (datetime) => {
    return moment(datetime).toISOString(true);
}
