import { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UsersService from "../../api/UsersService";
import MultiSelect from "../../components/form-components/MultiSelect";
import SingleTextInput from "../../components/form-components/SingleTextInput";
import GlobalState from '../../shared/GlobalState';
import { CButtonGroup, CButton } from '@coreui/react';
import * as appConstants from '../../AppConstants';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as sharedService from '../../shared/SharedService';
const ResearchAndAllocation = () => {
    const fields = [
        {
            key: 'project_name',
            label: 'Project name'
        },
        {
            key: 'allocated_users',
            type: 'scoped_slot',
            label: 'Allocated users'
        },
        {
            key: 'action',
            type: 'scoped_slot',
            label: 'Action'
        }
    ]

    const [researchList, setResearchList] = useState([]);
    const [research, setResearch] = useState({});
    const [userList, setUserList] = useState([]);
    const [state, setState] = useContext(GlobalState);

    useEffect(() => {
        getAllActiveUsers()
        getResearchAndUserAllocation()
    }, [])

    // fetches all active users
    const getAllActiveUsers = () => {
        UsersService.getAllUsersByStatus("Active", state.authHeader).then((res) => {
            setUserList(res.data);
        })
    }

    // get research and user allowcations
    const getResearchAndUserAllocation = () => {
        UsersService.getAllResearchAndAllocation(state?.authHeader).then(resp => {
            setResearchList(resp?.data)
        })
    }

    const handleChange = (e) => {
        setResearch({ ...research, [e.target.name]: e.target.value })
    }

    const saveOrUpdateResearchAllocation = () => {
        if (research?.id) {
            // update research and allocation
            UsersService.updateResearchAndAllocation(research?.id, research, state?.authHeader).then(resp => {
                setResearchList(resp?.data)
            });
        } else {
            // create new research and allocation
            UsersService.createResearchAndAllocation(research, state?.authHeader).then(resp => {
                setResearchList(resp?.data)

            })
        }
        setResearch({})
    }

    // get list of users by user ids
    const getUsersByList = (allocated_users) => {
        let result = [], userObj, user;
        if (userList?.length > 0 && allocated_users?.length > 0) {
            allocated_users?.map(item => {
                user = sharedService.findByAttributeValue(userList, 'id', item)
                if (user?.id) {
                    userObj = sharedService.setUserObject(user);
                    result.push(userObj?.name)
                }
            })
        }
        return String(result)
    }

    return (
        <>
            <Container>
                <Card border="primary" style={{ backgroundColor: "#ebf5ff" }}>
                    <Card.Header>
                        <Row>
                            <Col>
                                <b>Research and User Allocation</b>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col>
                                <SingleTextInput
                                    name="project_name"
                                    title="Project name"
                                    inputType="text"
                                    content={(research.project_name) ? (research.project_name) : ''}
                                    placeholder="Project name"
                                    manadatorySymbol={false}
                                    controlFunc={handleChange}
                                />
                            </Col>
                            <Col>
                                {/* <MultiSelect
                                    selectedOption={(research?.allocated_users) ? (research?.allocated_users) : []}
                                    name='allocated_users'
                                    label='Users'
                                    controlFunc={handleChange}
                                    options={userList}
                                    optionLabel={'first_name'}
                                    optionLabe2={'last_name'}
                                    optionValue={'id'}
                                /> */}
                                <SingleTextInput
                                    name="allocated_users"
                                    title="PI"
                                    inputType="text"
                                    content={(research.allocated_users) ? (research.allocated_users) : ''}
                                    placeholder="PI"
                                    manadatorySymbol={false}
                                    controlFunc={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col align='right'>
                                <CButtonGroup>
                                    <CButton
                                        color={appConstants.SAVE_BTN_COLOR}
                                        size="sm"
                                        title="Add research and allocation"
                                        onClick={saveOrUpdateResearchAllocation}
                                    >{research?.id ? 'Update' : 'Add'}</CButton>
                                    <CButton
                                        color={appConstants.EDIT_BTN_COLOR}
                                        size="sm"
                                        title="Cancel"
                                        onClick={() => setResearch({})}
                                    >Cancel</CButton>
                                </CButtonGroup>
                            </Col>
                        </Row>
                        <hr />
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {fields?.map((heading, index) => (
                                            <TableCell key={index + 1}>
                                                <b>{heading?.label}</b>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {researchList?.map((item, index) => (
                                        <TableRow key={index + 1}>
                                            {fields?.map(field => (
                                                <>
                                                    {field?.type !== 'scoped_slot' &&
                                                        <TableCell>{item[field.key] ? item[field.key] : ''}</TableCell>
                                                    }

                                                    {field?.type === 'scoped_slot' &&
                                                        <>
                                                            {field?.key === 'allocated_users' &&
                                                                <TableCell>
                                                                    {item?.allocated_users}
                                                                </TableCell>
                                                            }
                                                            {field?.key === 'action' &&
                                                                <TableCell>
                                                                    <CButton
                                                                        color={appConstants.SUBMIT_BTN_COLOR}
                                                                        size="sm"
                                                                        title='Edit research'
                                                                        onClick={() => setResearch(item)}
                                                                    ><FontAwesomeIcon icon={faPencilAlt} size='sm' /></CButton>
                                                                </TableCell>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}
export default ResearchAndAllocation;