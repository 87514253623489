import React from 'react'
import { Route, Routes } from "react-router-dom";
import CreateUser from './views/app-mgt/users/Create';
import UsersList from './views/app-mgt/users/List';
import Login from "./views/Login";

const NonAuthPages = () => {
    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
        </Routes>
    )
}
export default NonAuthPages;