import axios from './api-configs/axios-config';
import environment from '../environments/environment';

const API_BASE_URL = environment.apiUrl;

class UsersService {

    getUsers(authHeader) {
        return axios.get(API_BASE_URL + '/users', authHeader);
    }

    getAllUsers(authHeader) {
        return axios.get(API_BASE_URL + '/users/AllUsers', authHeader);
    }


    getUsersOrderByActiveStatus(unitId, role, department, active, authHeader) {
        return axios.get(API_BASE_URL + '/usersActive/' + unitId + '/' + role + '/' + department + '/' + active, authHeader);
    }

    getUsersWithRoles(authHeader) {
        return axios.get(API_BASE_URL + '/usersWithRoles', authHeader);
    }

    createUser(user) {
        return axios.post(API_BASE_URL + '/create-user', user);
    }

    findUserByUsername(username) {
        return axios.get(API_BASE_URL + '/find-user-by-username/' + username);
    }

    getUserById(userId, authHeader) {
        return axios.get(API_BASE_URL + '/users/' + userId, authHeader);
    }

    updateUser(user, userId, authHeader) {
        return axios.put(API_BASE_URL + '/updateUser/' + userId, user, authHeader);
    }

    updateUserPassword(user, userId) {
        return axios.put(API_BASE_URL + '/updateUserPassword/' + userId, user);
    }

    deleteUsers(userId, authHeader) {
        return axios.delete(API_BASE_URL + '/' + userId, authHeader);
    }
    getAllUsersByStatus(status, authHeader) {
        return axios.get(API_BASE_URL + '/users/get-by-status/' + status, authHeader)
    }
    getAllResearchAndAllocation(authHeader) {
        return axios.get(API_BASE_URL + '/researchAndAllocation', authHeader)
    }
    createResearchAndAllocation(research, authHeader) {
        return axios.post(API_BASE_URL + '/researchAndAllocation', research, authHeader)
    }
    updateResearchAndAllocation(research_id, research, authHeader) {
        return axios.put(API_BASE_URL + '/researchAndAllocation/' + research_id, research, authHeader)
    }
    getAllocatedUsersFromResearchId(research_id, authHeader) {
        return axios.get(API_BASE_URL + '/researchAndAllocation/allocated-users/' + research_id, authHeader)
    }
}

export default new UsersService();