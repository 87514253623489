import { Card, Container, Row, Col } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import * as appConstants from '../../AppConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
const VideoList = ({ list, keyValue, state, playVideo, selectedVideo, confirmAndProceed }) => {
    // checks video selection
    const checkVideoSelection = (item) => {
        if (selectedVideo?.id === item?.id) {
            return require('../../assets/playing.png')
        }
        else {
            return require('../../assets/videoDefault.png')
        }
    }

    return (
        <>
            {list.map((file, index) => (
                <Paper
                    style={{ border: selectedVideo?.id === file?.id ? '3px solid #32a3fa' : '3px solid #fff', pointerEvents: file?.patient_score?.final_score ? 'none' : 'auto' }}
                    elevation={appConstants.paperElevation} key={index + 1}
                    className='video-list-card'
                    onClick={() => confirmAndProceed(() => playVideo(file))}
                >
                    <Row style={{ alignItems: 'center', margin: 'inherit' }}>
                        <Col>
                            {file?.patient_score?.final_score ?
                                <FontAwesomeIcon icon={faCircleCheck} size='3x' color='green' />
                                :
                                <img src={checkVideoSelection(file)} width="50" height="50" />
                            }
                        </Col>
                        <Col>
                            <label>{file[keyValue]}</label>
                        </Col>
                    </Row>
                </Paper>
            ))}
        </>
    )
}
export default VideoList;