import React, { useContext, useEffect, useState, useRef } from 'react';
import PatientAssessmentService from '../../api/PatientAssessmentService';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { CButton } from '@coreui/react';
import * as appConstants from '../../AppConstants';
import GlobalState from '../../shared/GlobalState';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import VideoList from '../../components/form-components/VideoList';
import Button from '../../components/form-components/Button';
import * as sharedService from '../../shared/SharedService'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import environment from '../../environments/environment';

const VideoAssessment = (props) => {
    const params = useParams()
    const [state, setState] = useContext(GlobalState);
    const [videoScores, setVideoScores] = useState([])
    const [videoList, setVideoList] = useState([])
    const [videoCode, setVideoCode] = useState({})
    const [score, setScore] = useState(1)
    const [playing, setPlaying] = useState(false)
    const [prevAndPrevStack, setPrevAndNextStack] = useState([]);
    const [timeWithoutSeek, setTimeWithoutSeek] = useState();
    const max_score_range = 10;
    var videoPlayer = useRef(0);
    const MEDIA_BASE_URL = environment.mediaUrl;

    useEffect(() => {
        console.log("Video assessment list page loaded")
        getAllVideoList()
        console.log("player", videoPlayer)

        return () => {
            resetVideoAssessmentData()
        }

    }, []);

    const resetVideoAssessmentData = () => {
        console.log("in reset")
    }


    const getAllVideoList = () => {
        console.log("setting video list")
        PatientAssessmentService.getVideosBySetCode(state?.set_code, params?.assessment_id, state?.authHeader).then(resp => {
            console.log(resp?.data)
            setVideoList(resp?.data)
            setPreviousAndNextStack(resp?.data)
        })
    }

    const setVideoScoreAndDuration = (score) => {
        let playerCurrentTime = Math.round(videoPlayer?.current?.currentTime);
        setScore(score)
        let last_push_video_score = sharedService.findIndexByAttributeValue(videoScores, 'time', playerCurrentTime)
        let obj = {};
        if (last_push_video_score < 0) {
            obj.patient_id = params?.patient_id;
            obj.assessment_id = params?.assessment_id;
            obj.score = score;
            obj.video_code = videoCode?.video_code;
            obj.time = playerCurrentTime;
            setVideoScores([...videoScores, obj])
        } else {
            videoScores[last_push_video_score].time = playerCurrentTime;
        }
        // get time without seeking
        if (!videoPlayer.current.seeking) {
            setTimeWithoutSeek(videoPlayer.current.currentTime)
        }
    }

    const getVideoRatings = () => {
        let buttons = []
        for (let i = 1; i <= max_score_range; i++) {
            buttons.push(<Button
                size='md'
                color={appConstants.SUBMIT_BTN_COLOR}
                label={i}
                className='button-rating_group'
                handleClick={() => { setVideoScoreAndDuration(i) }}
            />)
        }
        return buttons;
    }

    const submitVideoScores = () => {
        let patientscoreObj = { patient_score_set: null, patient_video_details: {} }
        patientscoreObj.patient_score_set = videoScores
        patientscoreObj.patient_video_details.patient_id = params?.patient_id;
        patientscoreObj.patient_video_details.assessment_id = params?.assessment_id;
        patientscoreObj.patient_video_details.video_code = videoCode?.video_code;
        patientscoreObj.patient_video_details.video_id = videoCode?.id;
        // patientscoreObj.patient_video_details = videoAssessment
        patientscoreObj.patient_video_details.remarks = "SAMPLE1";

        PatientAssessmentService.createPatientIndividualScore(patientscoreObj, state?.authHeader).then(resp => {
            console.log("patient score data and video score data created", resp?.data)
            setVideoScores([])
            getAllVideoList()
        })
        console.log('video score', videoScores)
    }

    const playVideo = (videoObj, initial = false) => {
        setVideoScores([])
        setPlaying(false)
        setVideoCode(videoObj)
        setScore(1)
        if (initial === false) {
            videoPlayer.current.src = MEDIA_BASE_URL + '/' + state?.set_code + '/' + videoObj?.video_code + '.mp4';
        }
        else {
            videoPlayer.current.src = '';
        }
    }

    const next = () => {
        let currentIndex = sharedService.findIndexByAttributeValue(prevAndPrevStack, 'video_code', videoCode.video_code)
        let next_video = prevAndPrevStack[currentIndex + 1];
        playVideo(next_video)
    }

    const previous = () => {
        let currentIndex = sharedService.findIndexByAttributeValue(prevAndPrevStack, 'video_code', videoCode.video_code)
        let prev_video = prevAndPrevStack[currentIndex - 1];
        playVideo(prev_video)
    }

    const checkVideoIndex = (type) => {
        let currentIndex, videoListLength;
        currentIndex = sharedService.findIndexByAttributeValue(prevAndPrevStack, 'video_code', videoCode.video_code)
        videoListLength = prevAndPrevStack.length;
        if (type === 'next') {
            if (currentIndex < videoListLength - 1)
                return false;
        }
        if (type === 'prev') {
            if (currentIndex > 0)
                return false;
        }
        return true;
    }

    const setPreviousAndNextStack = (data) => {
        let filtered_video_list;
        filtered_video_list = data.filter(obj => !obj?.patient_score?.id)
        setPrevAndNextStack(filtered_video_list)
        if (filtered_video_list?.length > 0)
            playVideo(filtered_video_list[0])
        else
            playVideo(filtered_video_list, true)
    }

    const confirmAndProceed = (type = () => { }) => {
        if (playing || videoScores?.length > 1) {
            confirmAlert({
                title: 'Confirm to clear',
                message: 'Are you sure you want to clear the scores of the current video and proceed',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            type()
                            // deletePatientScore(patientscoreObj)
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {
                        }
                        // Cancel code here
                    }
                ]
            });
        } else {
            type()
        }
    }

    const getSubmitEnableStatus = () => {
        if (videoScores?.length > 1 && playing === false) {
            console.log("in if", videoScores)
            return false
        }
        return true
    }


    // handle on seek and restrict
    const handleOnSeek = () => {
        let delta = videoPlayer.current.currentTime - timeWithoutSeek;
        if (Math.abs(delta) > 0.01) {
            videoPlayer.current.currentTime = timeWithoutSeek;
        }
    }

    const handleVideoEnded = () => {
        setTimeWithoutSeek(0)
        setPlaying(false)
    }

    return (
        <>
            <Container className='bodyContainer'>
                <Card border="primary" style={{ backgroundColor: '#ebf5ff' }}>
                    <Card.Header>
                        <Row>
                            <Col>
                                <b>Video assessment</b>
                            </Col>
                            <Col align='right'>
                                <Link to={'/patient-assessment/list/' + params?.patient_id} >Assessment list</Link>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col lg="3" style={{ overflowY: 'auto', height: '500px' }}>
                                <VideoList
                                    list={videoList}
                                    keyValue='video_code'
                                    state={state}
                                    playVideo={playVideo}
                                    selectedVideo={videoCode}
                                    confirmAndProceed={confirmAndProceed}
                                />
                            </Col>
                            <Col lg="9">
                                <video
                                    onEnded={handleVideoEnded}
                                    onPlaying={() => setPlaying(true)}
                                    onTimeUpdate={() => setVideoScoreAndDuration(score)}
                                    ref={videoPlayer}
                                    width="800"
                                    height="500"
                                    type="video/mp4"
                                    controls
                                    onSeeking={handleOnSeek}
                                    controlsList="nodownload"
                                    className='video_player'
                                >
                                    <source type="video/mp4" />
                                </video>
                                <center>
                                    <Row>
                                        <Col md='2' lg='1' xl='1'>
                                            <CButton
                                                onClick={() => confirmAndProceed(previous)}
                                                disabled={checkVideoIndex('prev')}
                                            >
                                                Prev
                                            </CButton>
                                        </Col>
                                        <Col xs='12' sm='12' md='8' lg='10' xl='10'>{getVideoRatings()}</Col>
                                        <Col md='2' lg='1' xl='1' align='right'>
                                            <CButton
                                                onClick={() => confirmAndProceed(next)}
                                                disabled={checkVideoIndex('next')}
                                            >
                                                Next
                                            </CButton>
                                        </Col>
                                    </Row>
                                    <br /><br />
                                    {/* <Row>
                                        <Col>
                                            <SingleTextInput
                                                name="remarks"
                                                rows={5}
                                                controlFunc={handleChange}
                                                inputType="text"
                                                content={videoAssessment?.remarks}
                                                multiline={true}
                                                placeholder="Remarks"
                                            />
                                        </Col>
                                    </Row> */}
                                    <Row>
                                        <Col align='right'>
                                            <CButton
                                                disabled={getSubmitEnableStatus()}
                                                color={appConstants?.SUBMIT_BTN_COLOR}
                                                onClick={submitVideoScores}
                                            >Submit</CButton>
                                        </Col>
                                    </Row>
                                </center>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default VideoAssessment;