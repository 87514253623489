import axios from './api-configs/axios-config';
import environment from '../environments/environment';
const API_BASE_URL = environment.apiUrl;

class LoginService {
    login(user) {
        return axios.post(API_BASE_URL + '/login', user);
    }

    loggedUser(authHeader) {
        return axios.get(API_BASE_URL + '/loggedUser', authHeader);
    }

    checkEnteredPasswordIsCorrectOrNot(user, authHeader) {
        return axios.post(API_BASE_URL + '/checkEnteredPasswordIsCorrectOrNot', user, authHeader);
    }

    logout(authHeader) {
        return axios.get(API_BASE_URL + '/logout', authHeader);
    }

    deactivateUser(loginId, authHeader) {
        return axios.get(API_BASE_URL + '/login/deactivate/' + loginId, authHeader);
    }

    activateUser(loginId, authHeader) {
        return axios.get(API_BASE_URL + '/login/activate/' + loginId, authHeader);
    }

    getServerDateTime(authHeader) {
        return axios.get(API_BASE_URL + '/login/get-server-date-time', authHeader);
    }
}

export default new LoginService();