import React from 'react';
import { Modal } from 'react-bootstrap';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangePicker = (props) => (
    <Modal show={props.showDateRangePickerModal} onHide={props.closeDateRangePickerModal} backdrop="static" size="md">
        <Modal.Header closeButton>
            <Modal.Title>Date range picker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <DateRange
                editableDateInputs={true}
                onChange={(item) => { props.handleChangeFunc(item) }}
                moveRangeOnFirstSelection={false}
                ranges={props.daterange}
                dateDisplayFormat={'dd-MMM-yyyy'}
            />
        </Modal.Body>
        {/* <Modal.Footer>
        </Modal.Footer> */}
    </Modal>
);
export default DateRangePicker;