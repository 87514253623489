export const INPUT_FLOAT_LABEL = { 'id': 'standard-basic', variant: 'standard' };
export const INPUT_FILLED_LABEL = { 'id': 'filled-basic', variant: 'filled' };
export const INPUT_OUTLINED_LABEL = { 'id': 'outlined-basic', variant: 'outlined' };
export const buttonOutlined = 'outline';
export const buttonShape = 'rounded-pill';
export const SessionLogoutMsg = "Your session is expired please relogin.";
export const SERVICE_UNAVAILABLE_503_MSG = "503 Service Unavailable.! \nThe server is currently not ready to handle the request.  Please Contact the Administrator.";
export const SessionLogoutURL = "/";
export const paperElevation = 24;
export const MOBILE_MAX_WIDTH = 767;
export const max_patientPage = 6;

export const APP_INACTIVE_INTERVAL = 30; // in minutes
export const APP_INACTIVE_AUTO_LOGOUT_INTERVAL = 1; // in minutes

export const SAVE_BTN_COLOR = "primary";
export const SUBMIT_BTN_COLOR = "success";
export const CANCEL_BTN_COLOR = "warning";
export const EDIT_BTN_COLOR = "secondary";
export const INFO_BTN_COLOR = "info";
export const DELETE_BTN_COLOR = "danger";
export const DEFAULT_BTN_COLOR = "light";
export const LINK_BTN_COLOR = "link";

export const SUCCESS_BTN_COLOR = 'green';



export const LANGUAGES = [
    { 'id': 'Kannada', 'label': 'Kannada', 'value': 'Kannada' },
    { 'id': 'Hindi', 'label': 'Hindi', 'value': 'Hindi' },
    { 'id': 'Telugu', 'label': 'Telugu', 'value': 'Telugu' },
    { 'id': 'Tamil', 'label': 'Tamil', 'value': 'Tamil' },
    { 'id': 'Malayalam', 'label': 'Malayalam', 'value': 'Malayalam' },
    { 'id': 'Urdu', 'label': 'Urdu', 'value': 'Urdu' },
    { 'id': 'Tulu', 'label': 'Tulu', 'value': 'Tulu' },
    { 'id': 'Konkani', 'label': 'Konkani', 'value': 'Konkani' },
    { 'id': 'Oriya', 'label': 'Oriya', 'value': 'Oriya' },
    { 'id': 'Assamese', 'label': 'Assamese', 'value': 'Assamese' },
    { 'id': 'Kashmiri', 'label': 'Kashmiri', 'value': 'Kashmiri' },
    { 'id': 'Lambani', 'label': 'Lambani', 'value': 'Lambani' },
    { 'id': 'Marathi', 'label': 'Marathi', 'value': 'Marathi' },
    { 'id': 'Gujarathi', 'label': 'Gujarathi', 'value': 'Gujarathi' },
    { 'id': 'Bangla', 'label': 'Bangla', 'value': 'Bangla' },
    { 'id': 'English', 'label': 'English', 'value': 'English' },
    { 'id': 'Other Language', 'label': 'Other Language', 'value': 'Other Language' }
];


export const GENDER_OPTIONS = [
    { 'id': 'Male', 'label': 'Male', 'value': 'Male' },
    { 'id': 'Female', 'label': 'Female', 'value': 'Female' },
    { 'id': 'Other', 'label': 'Other', 'value': 'Other' }
];

export const ADMIN_USER_TYPES = [
    { 'id': 'Administrator', 'label': 'Administrator', 'value': 'Administrator' }
];


export const YES_NO_VALUE = [
    { 'id': 'Yes', 'label': 'Yes', 'value': 'Yes' },
    { 'id': 'No', 'label': 'No', 'value': 'No' },
];
export const STATES =
    [
        { "label": "Andhra Pradesh", "value": "Andhra Pradesh", "name": "state" },
        { "label": "Andaman and Nicobar islands", "value": "Andaman and Nicobar islands", "name": "state" },
        { "label": "Arunachal Pradesh", "value": "Arunachal Pradesh", "name": "state" },
        { "label": "Assam", "value": "Assam", "name": "state" },
        { "label": "Bihar", "value": "Bihar", "name": "state" },
        { "label": "Chhattisgarh", "value": "Chhattisgarh", "name": "state" },
        { "label": "Dadra and Nagar Haveli", "value": "Dadra and Nagar Haveli", "name": "state" },
        { "label": "Daman and Diu", "value": "Daman and Diu", "name": "state" },
        { "label": "Delhi", "value": "Delhi", "name": "state" },
        { "label": "Goa", "value": "Goa", "name": "state" },
        { "label": "Gujarat", "value": "Gujarat", "name": "state" },
        { "label": "Haryana", "value": "Haryana", "name": "state" },
        { "label": "Himachal Pradesh", "value": "Himachal Pradesh", "name": "state" },
        { "label": "Jammu and Kashmir", "value": "Jammu and Kashmir", "name": "state" },
        { "label": "Jharkhand", "value": "Jharkhand", "name": "state" },
        { "label": "Karnataka", "value": "Karnataka", "name": "state" },
        { "label": "Kerala", "value": "Kerala", "name": "state" },
        { "label": "Ladakh", "value": "Ladakh", "name": "state" },
        { "label": "Lakshadweep", "value": "Lakshadweep", "name": "state" },
        { "label": "Madhya Pradesh", "value": "Madhya Pradesh", "name": "state" },
        { "label": "Maharashtra", "value": "Maharashtra", "name": "state" },
        { "label": "Manipur", "value": "Manipur", "name": "state" },
        { "label": "Meghalaya", "value": "Meghalaya", "name": "state" },
        { "label": "Mizoram", "value": "Mizoram", "name": "state" },
        { "label": "Nagaland", "value": "Nagaland", "name": "state" },
        { "label": "Orissa", "value": "Orissa", "name": "state" },
        { "label": "Puducherry", "value": "Puducherry", "name": "state" },
        { "label": "Punjab", "value": "Punjab", "name": "state" },
        { "label": "Rajasthan", "value": "Rajasthan", "name": "state" },
        { "label": "Sikkim", "value": "Sikkim", "name": "state" },
        { "label": "Tamilnadu", "value": "Tamilnadu", "name": "state" },
        { "label": "Telangana", "value": "Telangana", "name": "state" },
        { "label": "Tripura", "value": "Tripura", "name": "state" },
        { "label": "Uttar Pradesh", "value": "Uttar Pradesh", "name": "state" },
        { "label": "Uttarakhand", "value": "Uttarakhand", "name": "state" },
        { "label": "West Bengal", "value": "West Bengal", "name": "state" }
    ];

export const COUNTRIES = [
    { "label": "India", "value": "India" },
    { "label": "Others", "value": "Others" }
];


export const USER_STATUS = {
    active: "Active",
    inactive: "Inactive"
}
export const export_headers = [
    { label: 'Identifier number', key: 'identifier_number' },
    { label: 'Assessment id', key: 'id' },
    { label: 'Assessment type', key: 'assessment_type' },
    { label: 'Name', key: 'name' },
    { label: 'Age', key: 'patient_details.age' },
    { label: 'Gender', key: 'patient_details.gender' },
    { label: 'EAT_EFN', key: 'EAT_EFN' },
    { label: 'EAT_EFP', key: 'EAT_EFP' },
    { label: 'EAT_EMN', key: 'EAT_EMN' },
    { label: 'EAT_EMP', key: 'EAT_EMP' },
    { label: 'EAT_YFN', key: 'EAT_YFN' },
    { label: 'EAT_YFP', key: 'EAT_YFP' },
    { label: 'EAT_YMN', key: 'EAT_YMN' },
    { label: 'EAT_YMP', key: 'EAT_YMP' },
    { label: 'EAT_P', key: 'EAT_P' },
    { label: 'EAT_N', key: 'EAT_N' },
    { label: 'EAT_TOT', key: 'EAT_TOT' }
]

export const PURPOSE = [
    { 'id': 'Clinical', 'label': 'Clinical', 'value': 'Clinical' },
    { 'id': 'Research', 'label': 'Research', 'value': 'Research' },
]
export const PROJECT = [
    { 'label': 'a', 'value': 'a' },
    { 'label': 'b', 'value': 'b' },
    { 'label': 'c', 'value': 'c' }
]
export const PI = [
    { 'label': 'x', 'value': 'x' },
    { 'label': 'y', 'value': 'y' },

]
export const PROJECT_PI_ASSOCICATION = {
    'a': 'x',
    'b': 'y',
    'c': 'x'
}
export const ASSESSMENT_TYPES = [
    { label: 'Cognitive', value: 'Cognitive' },
    { label: 'Affective', value: 'Affective' },
]

export const PATIENT_SCORE_EXPORT_FILE_FORMAT = ['patient_name', 'date_of_assessment']
export const ALL_PATIENT_SCORE_EXPORT_FILE_FORMAT = 'all_patinet_scores'