import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from 'react-bootstrap';
import * as appConstants from './../../AppConstants';
import GlobalState from './../../shared/GlobalState';
import SimpleReactValidator from 'simple-react-validator';
import SingleTextInput from '../../components/form-components/SingleTextInput';
import RadioButtonList from '../../components/form-components/RadioButtonList';
import SelectBox from "../../components/form-components/SelectBox";
import Button from '../../components/form-components/Button';
import KeyboardDatePickers from '../../components/form-components/KeyboardDatePicker';
import { toast } from 'react-toastify';
import Toaster from '../../components/form-components/Toaster';
import { useNavigate } from "react-router-dom";
import PatientService from "../../api/PatientService";
import * as momentService from '../../shared/MomentService';
import LoginService from "../../api/LoginService";
import UsersService from "../../api/UsersService";

const CreatePatient = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [state, setState] = useContext(GlobalState);
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [patient, setPatient] = useState({});
    const [currentDate, setCurrentDate] = useState({});
    const [researchList, setResearchList] = useState([]);
    const [allocatedUsers, setAllocatedUsers] = useState([]);
    useEffect(() => {
        getCurrentDate()
        if (params?.patient_id) {
            getPatientById()
        }
        console.log("Patient create page loaded")
    }, []);

    const getCurrentDate = () => {
        LoginService.getServerDateTime(state?.authHeader).then(resp => {
            setCurrentDate(resp?.data?.date)
        })
    }

    // fetches all the research projects
    const getResearchProjects = async () => {
        UsersService.getAllResearchAndAllocation(state?.authHeader).then(resp => {
            setResearchList(resp?.data)
        })
    }

    // fetches allocated users from selected research
    const getAllocatedUsersFromResearchId = async (research_id) => {
        UsersService.getAllocatedUsersFromResearchId(research_id, state?.authHeader).then(resp => {
            setAllocatedUsers(resp?.data)
            if (resp?.data) {
                patient.pi = resp?.data?.allocated_users;
            }
        })
    }

    const getPatientById = () => {
        PatientService.getPatientById(params?.patient_id, state?.authHeader).then((resp) => {
            if (resp?.data?.purpose === 'Research') {
                getResearchProjects();
            }
            if (resp?.data?.project) {
                getAllocatedUsersFromResearchId(resp?.data?.project);
            }
            setPatient(resp.data)
        })
    }

    const saveOrUpdatePatient = () => {
        if (patient?.id) {
            PatientService.updatePatient(patient?.id, patient, state?.authHeader).then(resp => {
                console.log("patient data updated")
                navigate('/patient/list')
            })
        } else {
            patient.created_by = state?.loggedUser?.username;
            PatientService.createPatient(patient, state?.authHeader).then(resp => {
                console.log("patient record created")
                navigate('/patient/list')
            })
        }
    }

    const validateForm = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            // log.debug('checks whether all the fields entered are valid', SharedService.getStateInfo(state))
            saveOrUpdatePatient()
        } else {
            validator.current.showMessages();
            forceUpdate(1)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        patient[name] = value;
        if (name === 'purpose') {
            if (value === 'Research') {
                getResearchProjects()
            }
            setPatient({ ...patient, [name]: value })
        }
        else if (name === 'project') {
            patient[name] = value;
            if (value !== (null || ''))
                getAllocatedUsersFromResearchId(value)
        }
        else
            if (name === 'phone_number') {
                if (validator.current.check(value, 'numeric|max:10')) {
                    setPatient({ ...patient, [name]: value })
                }
            } else if (name === 'age') {
                if (validator.current.check(value, 'numeric|max:2')) {
                    let dob = momentService.getDOBFromAge(value, "years")
                    patient.dob = dob;
                    setPatient({ ...patient, [name]: value })
                }
            } else if (name === 'first_name' || name === 'last_name') {
                if (validator.current.check(value, 'alpha_space')) {
                    setPatient({ ...patient, [name]: value })
                }
            } else {
                setPatient({ ...patient, [name]: value })
            }
    }


    const handleKeyBoardDatePickerChange = (date) => {
        if (date) {
            let age = momentService.getAgeFromDOB(date, currentDate, "years")
            patient.age = age
            patient.dob = date
            setPatient({ ...patient, dob: date })
        }
    }
    return (
        <>
            <Toaster position='bottom-left' duration='5000' />
            <Container>
                <Row>
                    <Col align="center" xs="12" sm="12" md="12" lg="12" xl="12"></Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <form onSubmit={validateForm} className="form-horizontal">
                            <Card border="primary">
                                <Card.Header align="center">
                                    <h2 className="card-header-h2">
                                        {patient?.id ? "Update " : "New "}
                                        patient record
                                    </h2>
                                </Card.Header>
                                <Card.Body>
                                    <Row className="formMargins">
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="first_name"
                                                title="First name"
                                                inputType="text"
                                                content={(patient?.first_name) ? (patient?.first_name) : ''}
                                                placeholder="First name"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('first_name', patient.first_name, 'required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="last_name"
                                                title="Last name"
                                                inputType="text"
                                                content={(patient.last_name) ? (patient.last_name) : ''}
                                                placeholder="Email"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('last_name', patient.last_name, 'required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="identifier_number"
                                                title="Identifier number"
                                                inputType="text"
                                                content={(patient.identifier_number) ? (patient.identifier_number) : ''}
                                                placeholder="Identifier number"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('identifier_number', patient.identifier_number, 'required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="email"
                                                title="Email"
                                                inputType="email"
                                                content={(patient.email) ? (patient.email) : ''}
                                                placeholder="Email"

                                                controlFunc={handleChange} />

                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="phone_number"
                                                title="Phone number"
                                                inputType="text"
                                                content={(patient.phone_number) ? (patient.phone_number) : ''}
                                                placeholder="Phone number"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('phone_number', patient.phone_number, 'numeric|max:10|required')}
                                        </Col>
                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <RadioButtonList
                                                title="Gender"
                                                name="gender"
                                                controlFunc={handleChange}
                                                manadatorySymbol={true}
                                                content={patient?.gender ? patient?.gender : ''}
                                                options={appConstants.GENDER_OPTIONS}
                                            />
                                            {validator.current.message('gender', patient.gender, 'required')}
                                        </Col>

                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <KeyboardDatePickers
                                                title="DOB"
                                                name='dob'
                                                placeholder="dob"
                                                content={patient?.dob ? patient?.dob : null}
                                                controlFunc={handleKeyBoardDatePickerChange}
                                                format='DD/MM/yyyy'
                                            />
                                        </Col>

                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <SingleTextInput name="age"
                                                title="Age"
                                                inputType="text"
                                                content={(patient.age) ? (patient.age) : ''}
                                                placeholder="age"
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('age', patient.age, 'required')}
                                        </Col>

                                        <Col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <RadioButtonList
                                                title="Purpose"
                                                name="purpose"
                                                controlFunc={handleChange}
                                                // manadatorySymbol={true}
                                                content={patient?.purpose ? patient?.purpose : ''}
                                                options={appConstants.PURPOSE}
                                            />
                                            {(patient.purpose === appConstants.PURPOSE[1].value) &&
                                                <Col xs="12" md="10" lg="10">
                                                    <SelectBox
                                                        name="project"
                                                        title="Select Project"
                                                        placeholder='Select Project'
                                                        controlFunc={handleChange}
                                                        options={researchList}
                                                        selectedOption={researchList?.length > 0 && patient.project ? patient.project : ''}
                                                        label={'project_name'}
                                                        value={'id'}
                                                    />
                                                    <Col xs="12" md="12" lg="12">
                                                        <SingleTextInput
                                                            name="pi"
                                                            title="Allocated PI"
                                                            inputType="text"
                                                            content={(patient.pi) ? (patient.pi) : ''}
                                                            placeholder="PI"
                                                            disabled={true}
                                                            manadatorySymbol={true}
                                                            controlFunc={handleChange} />
                                                    </Col>

                                                </Col>
                                            }
                                            {(patient.purpose === appConstants.PURPOSE[0].value) &&
                                                <Col xs="12" md="10" lg="10">
                                                    <SingleTextInput
                                                        name="referred_by"
                                                        title="Referred By"
                                                        inputType="text"
                                                        content={(patient.referred_by) ? (patient.referred_by) : ''}
                                                        placeholder="Referred By"
                                                        manadatorySymbol={false}
                                                        controlFunc={handleChange} />
                                                </Col>}
                                        </Col>
                                        <Col>
                                            <SingleTextInput
                                                name="diagnosis_clinical_details"
                                                title="Diagnosis and Clinical details"
                                                inputType="text"
                                                rows={5}
                                                multiline={true}
                                                content={(patient.diagnosis_clinical_details) ? (patient.diagnosis_clinical_details) : ''}
                                                placeholder="Diagnosis and Clinical details"
                                                manadatorySymbol={false}
                                                controlFunc={handleChange} />
                                        </Col>

                                    </Row>
                                </Card.Body>
                                <Card.Footer align="center">
                                    <Button label={"Cancel"}
                                        color={appConstants.DELETE_BTN_COLOR}
                                        handleClick={() => { navigate('/patient/list') }}
                                    />
                                    <Button label={params?.patient_id ? "Update & Continue" : "Save & Continue"}
                                        buttonStyle={{ margin: '0px 5px' }}
                                        color={appConstants.SUBMIT_BTN_COLOR}
                                        btnType={'submit'}
                                    />
                                </Card.Footer>
                            </Card>
                        </form >
                    </Col>
                    <Col align="center" xs="0" sm="1" md="2" lg="3" xl="3"></Col>
                </Row>
            </Container >
        </>
    )
}

export default CreatePatient;