import React, { useContext, useEffect, useState, useRef } from 'react';
import PatientAssessmentService from '../../api/PatientAssessmentService';
import { Card, Container, Row, Col, Modal, useAccordionButton, Accordion } from 'react-bootstrap';
import { CButtonGroup, CButton } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as appConstants from '../../AppConstants';
import GlobalState from '../../shared/GlobalState';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import LoginService from '../../api/LoginService'
import * as sharedService from '../../shared/SharedService';
import * as momentServices from '../../shared/MomentService';
import * as dateConfigs from '../../shared/configs/DateConfigs'
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export, Tooltip, Border } from 'devextreme-react/chart';
import Paper from '@mui/material/Paper';
import MeterialTable from '../../components/form-components/MeterialTable';
import Button from '../../components/form-components/Button';
import * as UserManagementConfigs from '../../shared/UserManagementConfig';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import DownloadToCsv from '../../components/form-components/DownloadToCsv';
import RadioButtonList from '../../components/form-components/RadioButtonList';

const PatientAssessmentList = () => {
    let fields = [
        {
            key: 'time',
            label: 'Time'
        },
        {
            key: 'score',
            label: 'Scale'
        }
    ]
    const params = useParams()
    const [state, setState] = useContext(GlobalState);
    const navigate = useNavigate();
    const [assessment, setAssessment] = useState({})
    const [assessmentList, setAssessmentList] = useState([])
    const [currentDate, setCurrentDate] = useState();
    const [assessmentSetCodes, setAssessmentSetCodes] = useState([])
    const [openVideoScores, setOpenVideoScores] = useState(false)
    const [selectedVideo, setSelectedVideo] = useState({});
    const [videoScoreList, setVideoScoreList] = useState([]);
    const [isLastAssessmentFinished, setIsLastAssessmentFinished] = useState(false)
    const [patientAssessmentRawScore, setPatientAssessmentRawScore] = useState([])
    const [download, setDownload] = useState(false);
    const [downloadFileName, setDownloadFileName] = useState();

    useEffect(() => {
        console.log("Patient assessment list page loaded")
        getPatientAssessmentsByPatientId();
        setState({ ...state, assessmentDate: null })
        getCurrentDate()
    }, []);

    // gets current date and time from the server
    const getCurrentDate = () => {
        LoginService.getServerDateTime(state?.authHeader).then(resp => {
            setCurrentDate(resp?.data)
            console.log("server date", resp?.data)
        })
    }

    // get patient assessment details with the patient info for export
    const getPatientInfoWithAssessment = (item) => {
        let obj, data = []
        obj = {
            patient_name: sharedService.getPatientName(state?.patient, " "),
            assessment_date: momentServices.formatDate(item?.date_of_assessment, dateConfigs.DEFAULT_DATE_FORMAT_UI),
            assessment_type: item?.assessment_type ? item?.assessment_type : null
        }
        data.push(obj)
        return data
    }

    // get patient assessments raw score
    const getPatientRawVideoScores = (item) => {
        setDownload(!download)
        PatientAssessmentService.getPatientRawVideoScores(params?.patient_id, item?.id, state?.authHeader).then(resp => {
            console.log("patient assessment raw score fetched...")
            if (resp?.data?.length > 0) {
                let resultantResp, dataResp2, dataResp1;

                dataResp1 = resp?.data;
                dataResp2 = getPatientInfoWithAssessment(item)
                resultantResp = [...dataResp2, ...dataResp1]

                setPatientAssessmentRawScore(resultantResp)
                setDownload(true)
                setDownloadFileName(getDownloadFileFormat(item))
                toast.success("Patient assessment raw score downloaded successfully")
            }
            else {
                toast.info("No patient assessment scores found")
            }
        })
    }

    const getDownloadFileFormat = (item) => {
        let fileformat = "";
        appConstants?.PATIENT_SCORE_EXPORT_FILE_FORMAT?.map(values => {
            if (values === 'patient_name')
                fileformat += sharedService.getPatientName(state?.patient, '_')
            else
                fileformat += "_" + item[values]
        })
        return fileformat;
    }

    const confirmAndProceed = (title, message, yes = () => { }, no = () => { }) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        yes()
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        no()
                    }
                    // Cancel code here
                }

            ]
        });
    }

    const confirmAndDownloadRawScore = (item) => {
        console.log("in conf")
        confirmAndProceed("Confirm to download", "Are you sure you want to download selected assessment raw scores ?", () => getPatientRawVideoScores(item))
    }
    // fetches patient assessments by patient id
    const getPatientAssessmentsByPatientId = () => {
        PatientAssessmentService.getPatientAssessmentsByPatientId(params?.patient_id, state?.authHeader).then(resp => {
            if (resp?.data?.length > 0) {
                console.log('patient assessments', resp?.data)
                setAssessmentList(resp?.data)
                setAssessmentSetCodes(Object.keys(resp?.data))
                getVideosBySetCode(resp?.data[0])
            } else {
                setIsLastAssessmentFinished(true)
            }
        })
    }
    const getVideosBySetCode = (last_assessment) => {
        PatientAssessmentService.getVideosBySetCode(last_assessment?.set_code, last_assessment?.id, state?.authHeader).then(resp => {
            console.log("in fetching videos", last_assessment?.patient_score.length, resp?.data?.length)
            if (last_assessment?.patient_score.length === 0) {
                setIsLastAssessmentFinished(false)
            }
            else if (last_assessment?.patient_score.length === resp?.data?.length) {
                setIsLastAssessmentFinished(true)
            }
        })
    }
    const gotoVideoAssessment = () => {
        // ask for confirmation
        assessment.patient_id = parseInt(params?.patient_id);
        assessment.date_of_assessment = currentDate?.date;
        assessment.user = sharedService.getUserObject(state?.loggedUser)
        assessment.created_by = sharedService.getUserObject(state?.loggedUser)
        PatientAssessmentService.createPatientAssessment(assessment, state?.authHeader).then(resp => {
            setState({ ...state, assessmentDate: assessment?.date_of_assessment })
            console.log("patient assessment created", resp?.data)
            navigate('/patient-video-assessment/list/' + params?.patient_id + '/' + resp?.data?.id)
        })
    }
    const continueAssessment = (item) => {
        setState({ ...state, assessmentDate: item?.date_of_assessment })
        navigate('/patient-video-assessment/list/' + params?.patient_id + '/' + item?.id)
    }
    const getUsername = (user) => {
        let firstname = user?.first_name ? user?.first_name + ' ' : '';
        let lastname = user?.last_name ? user?.last_name : '';
        let username = firstname + lastname;
        return username
    }
    const getVideoScores = (assessment_id, video_code) => {
        PatientAssessmentService.getPatientIndividualVideoScore(assessment_id, video_code, state?.authHeader).then(resp => {
            console.log('selected video scores', resp?.data)
            setVideoScoreList(resp?.data)
            setOpenVideoScores(true)
        })
    }

    const videoScores = (video, assessment_id) => {
        setSelectedVideo({ ...selectedVideo, videoCode: video?.video_code, assessment_id: assessment_id, score: video?.final_score })
        getVideoScores(assessment_id, video?.video_code)

    }
    const closeVideoScoreModal = () => {
        setOpenVideoScores(false)
    }

    const deletePatientScore = (patientscoreObj) => {
        PatientAssessmentService.deletePatientScoreById(patientscoreObj?.id, state?.authHeader).then(resp => {
            console.log("patient score deleted successfully")
            setAssessmentList(resp?.data)
            toast.success("Selected video score deleted successfully")
        })
    }

    const confirmDelete = (patientscoreObj) => {
        confirmAndProceed("Confirm to delete", 'Are you sure you want to delete selected video score', () => deletePatientScore(patientscoreObj))
    }

    const getPatientScoreWithPatientInfo = () => {
        let title = "", firstName, lastName, videoScore;
        firstName = state?.patient?.first_name;
        lastName = state?.patient?.last_name;
        videoScore = selectedVideo?.score ? parseFloat(selectedVideo?.score).toFixed(6) : '';
        title += firstName + " " + lastName + " " + "(" + selectedVideo?.videoCode + ": " + videoScore + ")";

        return title;
    }

    const handleChange = (e) => {
        setAssessment({ ...assessment, [e.target.name]: e.target.value })
    }

    const videoScoresModal = () => {
        return (
            <Modal show={openVideoScores}
                onHide={closeVideoScoreModal}
                backdrop="static"
                keyboard={false}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered>
                <Modal.Header closeButton className='modal_header_footer'>
                    <Modal.Title>{selectedVideo?.videoCode}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <MeterialTable
                                fields={fields}
                                data={videoScoreList}
                            />
                        </Col>
                        <Col>
                            <Paper elevation={24}>
                                <Chart
                                    id="chart"
                                    title={getPatientScoreWithPatientInfo()}
                                    dataSource={videoScoreList}
                                >
                                    <CommonSeriesSettings argumentField="time" type="line" />
                                    <Series
                                        name={selectedVideo?.videoCode}
                                        valueField="score"
                                        stack="score"
                                        color="#00BCD4"
                                    />

                                    <ValueAxis>
                                        <Title text="" />
                                    </ValueAxis>
                                    <Legend position="inside"
                                        columnCount={3}
                                        horizontalAlignment="right">
                                        <Border visible={true} />
                                    </Legend>
                                    <Export enabled={true} />
                                    <Tooltip enabled={true} />
                                </Chart>
                            </Paper>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer className='modal_header_footer'>
                    <Button
                        size="sm"
                        color={appConstants.DELETE_BTN_COLOR}
                        handleClick={closeVideoScoreModal}
                        title={'close video score modal'}
                        label={"Close"}
                    />
                </Modal.Footer>
            </Modal>
        )
    }

    const CustomToggle = ({ children, eventKey }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <Button
                handleClick={decoratedOnClick}
                title={children}
                label={children}
                size='sm'
            />
        );
    }

    // calculating EAT_P, EAT_N and EAT_TOT average
    const getEATPositiveAndNegativeAverage = (patient_scores, patientAssessment) => {
        let ptotal = 0, pcount = 0, ntotal = 0, ncount = 0, compareKey1 = "P", compareKey2 = "N", p_average, n_average, total;
        if (patient_scores?.length > 0) {
            patient_scores?.map(score => {
                if (score['video_code']?.search(compareKey1) > 0) {
                    ptotal += parseFloat(score['final_score'])
                    pcount += 1
                } else if (score['video_code']?.search(compareKey2) > 0) {
                    ntotal += parseFloat(score['final_score'])
                    ncount += 1
                } else {
                    return
                }
            })
            p_average = parseFloat((parseFloat(ptotal) / parseInt(pcount)).toFixed(6))
            n_average = parseFloat((parseFloat(ntotal) / parseInt(ncount)).toFixed(6))
            total = parseFloat(((p_average + n_average) / 2).toFixed(6))
        }
        return (
            <Row>
                <Col>
                    Assessment type: <b>{patientAssessment?.assessment_type ? patientAssessment?.assessment_type : "--"}</b>
                </Col>
                <Col>
                    EAT_P: <b>{p_average ? p_average : "--"}</b>
                </Col>
                <Col>
                    EAT_N: <b>{n_average ? n_average : "--"}</b>
                </Col>
                <Col>
                    EAT_TOT: <b>{total ? total : "--"}</b>
                </Col>
            </Row>

        )
    }

    const getSubmissionDates = (item) => {
        let videoSubmissionDate = new Set()
        item?.patient_score?.map((video, index) => (
            videoSubmissionDate.add(momentServices.formatDate(video.date_of_submission, dateConfigs.DEFAULT_DATE_FORMAT_UI))
        ))
        return Array.from(videoSubmissionDate).join(", ")
    }

    const handleSubmitSet = (item) => {
        let obj = { is_submitted: true, patient_id: item?.patient_id }
        confirmAlert({
            title: "Confirm to submit the set",
            message: "Are you sure you want to submit the current set?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        assessment.updated_by = sharedService.getUserObject(state?.loggedUser)
                        PatientAssessmentService.updatePatientAssessment(item.id, obj, state.authHeader).then(resp => {
                            setAssessmentList(resp.data)
                            toast.info("Current Set is submitted")
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {

                    }
                    // Cancel code here
                }

            ]
        });

    }

    const confirmAndDeleteAssessment = (item) => {
        confirmAlert({
            title: "Confirm to delete the complete assessment",
            message: "Are you sure you want to delete the current set?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        assessment.updated_by = sharedService.getUserObject(state?.loggedUser)
                        PatientAssessmentService.deleteAssessmentById(item.id, item?.patient_id, state.authHeader).then(resp => {
                            setAssessmentList(resp.data)
                            toast.info("Patient Assessment Deleted Successfully")
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    return (
        <>
            {
                videoScoresModal()
            }
            {(download === true && patientAssessmentRawScore?.length > 0 && downloadFileName !== (null || '' || undefined)) &&
                <DownloadToCsv
                    fileName={downloadFileName}
                    data={patientAssessmentRawScore}
                />
            }
            <Container>
                <Card border="primary" style={{ backgroundColor: "#ebf5ff" }}>
                    <Card.Header>
                        <Row>
                            <Col lg='12'>
                                Assessment list
                            </Col>
                            {((assessmentList?.[0]?.is_submitted === true) || (!assessmentList?.length > 0)) &&
                                <>
                                    <Col style={{ paddingLeft: "11%" }}>
                                        <RadioButtonList
                                            title="Assessment type"
                                            name="assessment_type"
                                            controlFunc={handleChange}
                                            content={assessment?.assessment_type ? assessment?.assessment_type : ''}
                                            options={appConstants.ASSESSMENT_TYPES}
                                        />
                                    </Col>

                                    <Col align='right'>
                                        <CButton
                                            color={appConstants.SUBMIT_BTN_COLOR}
                                            size="sm"
                                            title='Start New Assessment'
                                            onClick={gotoVideoAssessment}
                                            disabled={!assessment?.assessment_type}
                                        >Start New Assessment</CButton>
                                    </Col>

                                </>
                            }
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Accordion>
                            {assessmentList?.map((item, index) => (
                                <Accordion.Item key={index + 1} eventKey={index}>
                                    <Accordion.Header>
                                        <Row style={{ display: 'contents' }}>
                                            <Col sm="12" md="3" lg="3">
                                                <label>DOA: <b>{getSubmissionDates(item)}</b></label>
                                            </Col>
                                            <Col sm="12" md="3" lg="3">
                                                <label>Set code: <b>{item?.set_code ? item?.set_code : ''}</b></label>
                                            </Col>
                                            <Col sm="12" md="3" lg="3">
                                                <label>Edited by: <b>{getUsername(item?.user)}</b></label>
                                            </Col>
                                            <Col align='right' sm="12" md="3" lg="3">
                                                <CButtonGroup>
                                                    {state?.loggedUser?.role_code === UserManagementConfigs.ROLE_CODES.administrator_role_code &&
                                                        <CButton
                                                            size='sm'
                                                            color={appConstants.SUBMIT_BTN_COLOR}
                                                            onClick={() => confirmAndDownloadRawScore(item)}
                                                        >
                                                            Download raw scores
                                                        </CButton>
                                                    }
                                                    {state?.loggedUser?.role_code === UserManagementConfigs.ROLE_CODES.administrator_role_code &&
                                                        <CButton
                                                            size='sm'
                                                            color={appConstants.DELETE_BTN_COLOR}
                                                            onClick={() => confirmAndDeleteAssessment(item)}
                                                        >
                                                            Delete
                                                        </CButton>
                                                    }
                                                    {(index === 0 && item.is_submitted != true) &&
                                                        <CButton
                                                            size='sm'
                                                            onClick={() => continueAssessment(item)}
                                                        >
                                                            Continue
                                                        </CButton>
                                                    }
                                                </CButtonGroup>
                                            </Col>
                                        </Row>

                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {getEATPositiveAndNegativeAverage(item?.patient_score, item)}
                                        <br />
                                        <Row>
                                            {item?.patient_score?.map((video, index) => (
                                                <Col xs='12' md='12' lg='4' key={index + "_row" + index} className='mb-2'>
                                                    <Paper elevation={appConstants.paperElevation}>
                                                        <Card>
                                                            <Card.Header style={{ backgroundColor: 'darkkhaki' }}>
                                                                <Row>
                                                                    <Col>
                                                                        {video?.video_code}
                                                                    </Col>
                                                                    <Col align='right'>
                                                                        <CButtonGroup>
                                                                            <CButton
                                                                                size="sm"
                                                                                title='View video score'
                                                                                onClick={() => videoScores(video, item?.id)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </CButton>
                                                                            {state?.loggedUser?.role_code === UserManagementConfigs.ROLE_CODES.administrator_role_code &&
                                                                                <CButton
                                                                                    size="sm"
                                                                                    title='Delete'
                                                                                    onClick={() => confirmDelete(video)}
                                                                                    color={appConstants.DELETE_BTN_COLOR}
                                                                                >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </CButton>
                                                                            }
                                                                        </CButtonGroup>

                                                                    </Col>
                                                                </Row>

                                                            </Card.Header>
                                                            <Card.Body style={{ backgroundColor: '#d6eadf' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <label>Score: <b>{video?.final_score ? parseFloat(video?.final_score).toFixed(6) : ''}</b></label>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <label>Date of submission : <b>{momentServices.formatDate(video.date_of_submission, dateConfigs.DEFAULT_DATE_FORMAT_UI)}</b></label>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>

                                                    </Paper>
                                                </Col>

                                            ))}

                                        </Row>
                                        {index === 0 && item?.is_submitted !== true && < Row >
                                            <Col xs="12" md="6" lg="12" style={{ display: 'flex', justifyContent: 'right' }} >
                                                <CButton
                                                    size="sm"
                                                    title='Submit set has completed'
                                                    onClick={() => handleSubmitSet(item)}
                                                    color={appConstants.SUBMIT_BTN_COLOR}
                                                    disabled={!(item?.patient_score?.length > 0)}
                                                >
                                                    Submit set
                                                </CButton>
                                            </Col>
                                        </Row>}
                                    </Accordion.Body>
                                </Accordion.Item>
                                // <Card>
                                //     <Card.Header>
                                //         <Row>
                                //             <Col>
                                //                 <label>Date of assessment: <b>{momentServices.formatDate(item?.date_of_assessment, dateConfigs.DEFAULT_DATE_FORMAT_UI)}</b></label>
                                //             </Col>
                                //             <Col>
                                //                 <label>Edited by: <b>{getUsername(item?.user)}</b></label>
                                //             </Col>
                                //         </Row>
                                //         <Row>
                                //             <Col>
                                //                 <label>Set code: <b>{item?.set_code ? item?.set_code : ''}</b></label>
                                //             </Col>

                                //             <Col align='right'>
                                //                 <CButton size='sm'>Continue</CButton>
                                //             </Col>
                                //             <Col align='right'>
                                //                 <CustomToggle eventKey="1">View</CustomToggle>
                                //             </Col>
                                //         </Row>

                                //     </Card.Header>
                                //     <Accordion.Collapse eventKey="1">
                                //         <Card.Body>Hello! I'm the body</Card.Body>
                                //     </Accordion.Collapse>
                                // </Card>

                            ))}
                        </Accordion>
                    </Card.Body>
                </Card>
            </Container >
        </>
    );
}

export default PatientAssessmentList;