import React from 'react'
import { CButton } from '@coreui/react';
import CIcon from '@coreui/icons-react'
import * as appConstant from '../../AppConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSpinner } from '@coreui/react';

const Button = (props) => (
    <>
        <CButton className={(props.className) ? props.className : ''}
            variant={!props.variant ? appConstant.buttonOutlined : props.variant}
            color={props.color}
            shape={(props.shape === false) ? '' : appConstant.buttonShape}
            onClick={props.handleClick}
            style={props.buttonStyle ? props.buttonStyle : {}}
            title={props.title}
            disabled={props.isDisabled ? props.isDisabled : false}
            readOnly={props.isReadonly ? props.isReadonly : false}
            size={(props.size) ? (props.size) : 'lg'}
            type={props.btnType ? props.btnType : 'button'}
        >
            {(props.iconLeft && (props.isIconAndSpinnerRequired || !props.isSpinnerRequired)) &&
                <CIcon size="sm" name={props.iconName} />
            }
            {props.iconDisplay && (props.isIconAndSpinnerRequired || !props?.isSpinnerRequired) &&
                <FontAwesomeIcon size="md" icon={props.iconName} />
            }
            {props.isSpinnerRequired && props.spinnerVariable &&
                <CSpinner color="light" size="sm" />
            }
            {' ' + props.label ? props.label : '' + ' '}
            {props.iconRight && (props.isIconAndSpinnerRequired || !props?.isSpinnerRequired) &&
                <CIcon size="sm" name={props.iconName} />
            }
            {props.iconDisplayAfter && (props.isIconAndSpinnerRequired || !props?.isSpinnerRequired) &&
                <FontAwesomeIcon size="md" icon={props.iconName} />
            }
        </CButton>
    </>
);

export default Button;