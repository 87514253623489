import React, { useState, useRef, useContext } from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import SingleTextInput from './../components/form-components/SingleTextInput';
import Button from './../components/form-components/Button';
import SimpleReactValidator from 'simple-react-validator';
import * as appConstants from './../AppConstants';
import PasswordInput from "../components/form-components/PasswordInput";
import Toaster from './../components/form-components/Toaster';
import { toast } from 'react-toastify';
import UsersService from './../api/UsersService';
import LoginService from '../api/LoginService';
import * as SharedService from './../shared/SharedService';
import GlobalState from './../shared/GlobalState';

const Login = () => {
    const [state, setState] = useContext(GlobalState);
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const validator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        user[e.target.name] = e.target.value;
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handleBlurChange = (e) => {
        if (e.target.name === 'username' && e.target.value) {
            findUserByUsername(e.target.value);
        }
    }

    const validateForm = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            console.log('all valid');
            login(e);
        } else {
            console.log('Not Valid')
            toast.error('Validation Check!')
            validator.current.showMessages();
            forceUpdate(1)
        }
    }

    const login = (e) => {
        let password = user.password;
        var md5 = require('md5');
        let encryptPwd = md5(user.password)
        user.password = encryptPwd
        e.preventDefault();
        LoginService.login(user).then(res => {
            if (res?.data?.code !== 200) {
                toast.error(res.data.message)
            } else {
                setUser({});
                console.log(res.data)
                setState(state => ({ ...state, authHeader: SharedService.getAuthHeaders(res.data.data.current_auth_token) }));

                setState(state => ({ ...state, loggedUser: res.data.data }));
                setState(state => ({ ...state, isAuthenticated: true, showLoginToast: true, isSessionLoggedOut: false }));
                navigate('/patient/list');
            }
        }).catch(error => {
            // your error handling goes here
            console.log('error', error)
            toast.error('Invalid username or password');
            user.password = password;

        })
    }

    const findUserByUsername = (username) => {
        UsersService.findUserByUsername(username).then(res => {
            if (res?.data?.code != 409) {
                toast.error(res?.data?.message);
            }
            else {
                console.log('user')
            }
        });
    }

    const gotoRegister = () => {
        navigate('/register');
    }
    const gotoForgotPass = () => {
        navigate('/forgot-password');
    }

    return (
        <>
            {/* <Toaster /> */}
            <Container className="">
                <Row>
                    <Col align="center" xs="0" sm="2" md="3" lg="4" xl="4"></Col>
                    <Col xs="12" sm="8" md="6" lg="4" xl="4">
                        <form onSubmit={(e) => { validateForm(e) }}>
                            <Card border="primary" style={{ backgroundColor: '#ebf5ff' }}>
                                <Card.Header align="center">
                                    <h2 className="card-header-h2">Login</h2></Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <SingleTextInput name="username"
                                                title="Username"
                                                inputType="text"
                                                content={(user.username) ? (user.username) : ''}
                                                manadatorySymbol={true}
                                                controlFunc={handleChange}
                                                controlBlurFunc={handleBlurChange} />
                                            {validator.current.message('username', user.username, 'required')}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PasswordInput name="password"
                                                title="Password"
                                                content={(user.password) ? (user.password) : ''}
                                                manadatorySymbol={true}
                                                controlFunc={handleChange} />
                                            {validator.current.message('password', user.password, 'required')}
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col align="right">
                                            <Button label={(!loading) ? "Login" : "Loading"}
                                                color={appConstants.SUBMIT_BTN_COLOR}
                                                btnType={'submit'}
                                                isDisabled={loading ? true : false}
                                                isSpinnerRequired={true}
                                                spinnerVariable={loading}></Button>
                                        </Col>
                                    </Row></Card.Footer>
                            </Card>
                        </form>
                    </Col>
                    <Col align="center" xs="0" sm="2" md="3" lg="4" xl="4"></Col>
                </Row>
            </Container>
        </>
    );
}

export default Login;