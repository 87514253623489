import axios from './api-configs/axios-config';
import environment from '../environments/environment';
const PATIENT_API_BASE_URL = environment.apiUrl + "/patient";

class PatientService {
    getAllPatients(authHeader) {
        return axios.get(PATIENT_API_BASE_URL, authHeader)
    }

    getPatientById(patient_id, authHeader) {
        return axios.get(PATIENT_API_BASE_URL + '/' + patient_id, authHeader)
    }

    createPatient(patient, authHeader) {
        return axios.post(PATIENT_API_BASE_URL, patient, authHeader)
    }

    updatePatient(patient_id, patient, authHeader) {
        return axios.put(PATIENT_API_BASE_URL + '/' + patient_id, patient, authHeader)
    }
    getAllPatientsData(start_date, end_date, users, authHeader) {
        return axios.get(PATIENT_API_BASE_URL + '/get-data-report/' + start_date + '/' + end_date + '/' + users, authHeader)
    }
    getPatientListByUser(userId, authHeader) {
        return axios.get(PATIENT_API_BASE_URL + '/get-patientList-by-userName/' + userId, authHeader)
    }
    getPatientSearch(patientSearch, authHeader) {
        return axios.get(PATIENT_API_BASE_URL + '/search/' + patientSearch, authHeader)
    }
    deletePatientById(patient_id, userId, authHeader) {
        return axios.delete(PATIENT_API_BASE_URL + '/delete/' + patient_id + '/' + userId, authHeader)
    }
}
export default new PatientService();