import React from "react";

const SingleCheckbox = (props) => {
    return (
        <>
            <label variant="custom-checkbox" inline="true">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                    defaultChecked={(props.default) ? true : false}
                    id={props.id ? props.id : null}
                    name={props.name}
                    onChange={props.controlFunc}
                    value={props.value}
                    checked={props.content}
                    type="checkbox"
                    disabled={(props.disabled) ? true : false}
                /> &nbsp;&nbsp;{props.title}

            </label>
        </>
    );
}

export default SingleCheckbox;