import React, { useEffect, useState, useRef, useContext } from "react";
import LoginService from './api/LoginService';
import GlobalState from './shared/GlobalState';
import Toaster from './components/form-components/Toaster';
import { toast } from 'react-toastify';
import UsersService from './api/UsersService';
import PasswordInput from "./components/form-components/PasswordInput";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Container, Row, Col, Form, Alert } from 'react-bootstrap';
import Button from './components/form-components/Button';
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
}));

const ChangePassword = (props) => {
    const classes = useStyles();
    const [state, setState] = useContext(GlobalState);
    const [user, setUser] = useState({});
    const [resetPwdObj, setResetPwdObj] = useState({
        username: state.loggedUser.username
    });
    const [passwordShown, setPasswordShown] = useState({});
    const [status, setStatus] = useState("");

    useEffect(() => {
        console.log('calling change password')
        setState({ ...state, patient: {} });
    }, []);

    const togglePasswordVisiblity = (name) => {
        passwordShown[name] = !passwordShown[name];
        setPasswordShown({ ...passwordShown, passwordShown });
    };

    const handleBlurChange = (e) => {

        if (state.loggedUser.username != state.loggedUser.password)
            setResetPwdObj({ ...resetPwdObj, [e.target.name]: e.target.value });
    }

    const handleUserChange = (e) => {
        setResetPwdObj({ ...resetPwdObj, [e.target.name]: e.target.value });
    }

    const validateCurrentPassword = () => {
        // console.log("validate current password fun is calling")
        const md5 = require('md5')
        let Obj = resetPwdObj
        // password encryption
        Obj.password = md5(Obj.password)
        LoginService.checkEnteredPasswordIsCorrectOrNot(Obj).then(res => {
            if (res.data && res.data.code === 401) {
                toast.error('Incorrect Password');
            }
            else if (res.data && !(res.data.code === 401)) {
                console.log("code is 200")
                setStatus('Validated');
            }

        }).catch(error => {
            // your error handling goes here
            // console.log('error', error)
            toast.error('The entered password is incorrect');
        });
    }

    const saveOrUpdateUser = (e, userId) => {
        if (resetPwdObj.npassword !== resetPwdObj.confirmPass) {
            alert("Passwords do not match. Please enter again.");
            return false;
        }
        else {
            const md5 = require('md5')
            e.preventDefault();
            userId = state.loggedUser.id
            let userObj = {
                'id': userId,
                'username': resetPwdObj.username,
                'password': md5(resetPwdObj.npassword)
            }
            if (state.loggedUser.force_update_password == true)
                userObj.force_update_password = false;
            UsersService.updateUserPassword(userObj, userId).then(res => {

                if (res.data.status === 200) {
                    setStatus('Success')
                    if ('onHide' in props)
                        props?.onHide();
                    setResetPwdObj({});
                }
                else {
                    alert('Failed to update password')
                }
            });
        }
    }

    return (
        <Container style={{ maxWidth: '500px' }}>
            <Toaster
                position='bottom-left'
                duration='5000'
            />
            <Card>
                <Card.Body>

                    {(status === '') && <>
                        <div className={classes.root} align={'center'}>
                            <h4>Enter Current Password</h4>
                        </div>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <PasswordInput name="password"
                                    title="Current Password"
                                    content={(resetPwdObj.password) ? (resetPwdObj.password) : ''}
                                    controlFunc={handleBlurChange}
                                />
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12" align="center">
                                <Button
                                    color="primary"
                                    label={'submit'}
                                    handleClick={() => { validateCurrentPassword() }}
                                    disabled={resetPwdObj.password ? false : true}
                                >
                                </Button>
                            </Col>
                        </Row>
                    </>}
                    {(status === 'Validated') && <h4>Enter New Password</h4>}
                    {(status === 'Validated') &&
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <PasswordInput name="npassword"
                                    title="New Password"
                                    content={(resetPwdObj.npassword) ? (resetPwdObj.npassword) : ''}
                                    controlFunc={handleUserChange}
                                />
                            </Col>
                        </Row>}
                    {(status === 'Validated') && <h4>Confirm Password</h4>}
                    {(status === 'Validated') && <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <PasswordInput name="confirmPass"
                                title="Confirm Password"
                                content={(resetPwdObj.confirmPass) ? (resetPwdObj.confirmPass) : ''}
                                controlFunc={handleUserChange}
                            />
                        </Col>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12" align="center">
                            <Button color="primary"
                                disabled={(resetPwdObj.npassword && resetPwdObj.confirmPass) ? false : true}
                                // color={appConstants.SAVE_BTN_COLOR}
                                handleClick={saveOrUpdateUser}
                                label={'update'}
                            >

                            </Button>
                        </Col>
                    </Row>}
                    {status === 'Success' && <Row>
                        <Col align="center">
                            <h4 style={{ color: 'green' }}>Your new password is updated successfully</h4>
                            <h5>click &nbsp;&nbsp;
                                <Link to="/login">here</Link>
                                &nbsp;&nbsp;&nbsp;to log back in
                            </h5>
                        </Col>
                    </Row>}
                </Card.Body>
            </Card>
        </Container>
    )
}

export default ChangePassword
