import React, { useContext, useEffect, useState } from 'react';
import PatientService from '../../api/PatientService';
import UserService from '../../api/UsersService';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { CButtonGroup, CButton, CCardBody } from '@coreui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faPhone, faEnvelope, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as appConstants from '../../AppConstants';
import GlobalState from '../../shared/GlobalState';
import { useNavigate } from 'react-router-dom';
import SingleTextInput from '../../components/form-components/SingleTextInput';
import SelectBox from '../../components/form-components/SelectBox';
import * as SharedService from '../../shared/SharedService';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import * as _ from 'lodash';
import * as UsermanagementConfigs from '../../shared/UserManagementConfig';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';

const PatientList = () => {
    const [state, setState] = useContext(GlobalState);
    const [patientList, setPatientList] = useState([]);
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();
    const [tempList, setTemplist] = useState({ data: [] });
    const [page, setPage] = React.useState(1);
    const loggedUser = (state?.loggedUser?.role_code === UsermanagementConfigs.ROLE_CODES.administrator_role_code) ? 0 : (state?.loggedUser?.id)

    useEffect(() => {
        console.log("Patient List page loading")
        setState({ ...state, patient: null, assessmentDate: null })
        getPatientListByUser(loggedUser)
        //getAllPatients()
        getAllUsers()
    }, []);

    const getAllPatients = () => {
        PatientService.getAllPatients(state?.authHeader).then(resp => {
            setPatientList(resp?.data)
            let data = SharedService.setRowstoPage(resp.data, tempList.data)
            console.log(data)
            data = _.chunk(data, appConstants.max_patientPage);
            console.log(data)
            setTemplist({ ...tempList, data: data });
        })
    }
    const editPatient = (patient) => {
        navigate('/patient/update/' + patient?.id);
    }
    const gotoAssessmentList = (patient) => {
        setState({ ...state, patient: patient })
        navigate('/patient-assessment/list/' + patient?.id)
    }
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            getPatientSearch(e.target.value.trimStart())
            let data = SharedService.filterListObjectsByValue(patientList, e.target.value)
            console.log(data)
            data = _.chunk(data, appConstants.max_patientPage);
            console.log(data)
            setTemplist({ ...tempList, data: data });
            console.log(tempList)
        }

    }
    const getPatientSearch = (searchText) => {
        console.log('searchText', searchText)
        PatientService.getPatientSearch(searchText, state.authHeader).then(resp => {
            console.log(resp.data)
            setPatientList(resp.data)
        })
    }

    const handleChange = (event, value) => {
        setPage(value);
        console.log(value, page);
        console.log(tempList.data[page])
    };

    const getAllUsers = () => {
        let list = [{ 'id': -99, 'first_name': 'All', 'last_name': '', 'username': '' }];
        UserService.getUsers(state?.authHeader).then(resp => {
            setUserList([...list, ...resp.data])
        })
    }

    const getPatientListByUser = (userId) => {
        console.log(userId)
        PatientService.getPatientListByUser(userId, state.authHeader).then(resp => {
            chunkPatientList(resp?.data)
        })
    }

    const chunkPatientList = (listData) => {
        setPatientList(listData)
        tempList.data = listData
        let data = SharedService.setRowstoPage(listData, tempList.data)
        data = _.chunk(data, appConstants.max_patientPage);
        setTemplist({ ...tempList, data: data });
    }

    const handleFilterChange = (e) => {
        const selectedValue = e.target.value
        if (selectedValue) {
            getPatientListByUser(selectedValue);
        }
    }

    const confirmAndDeletePatientData = (item) => {
        confirmAlert({
            title: "Confirm to delete the patient",
            message: "Are you sure you want to delete the current patient data?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        PatientService.deletePatientById(item.id, loggedUser, state.authHeader).then(resp => {
                            chunkPatientList(resp?.data)
                            toast.info("Patient Deleted Successfully")
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    return (
        <>

            <Container>
                <Card border="primary" style={{ backgroundColor: '#ebf5ff' }}>
                    <Card.Header>
                        <Row>

                            <Col xs="8" md="4" lg="4" xl="4" >
                                <h2 className="card-header-h2 card-list-header">Patient list</h2>
                            </Col>

                            <Col xs="8" md="4" lg="4" xl="4" >
                                <SingleTextInput name="search"
                                    inputType="text"
                                    placeholder="Patient Search..."
                                    controlFunc={handleSearch}
                                    controlKeyDownFunc={handleSearch}
                                    variant="outlined"

                                />
                            </Col>
                            {state?.loggedUser?.role_code === UsermanagementConfigs.ROLE_CODES.administrator_role_code && <Col xs="8" md="2" lg="2" xl="2" >
                                <SelectBox
                                    name="user"
                                    title="Users"
                                    controlFunc={handleFilterChange}
                                    options={userList}
                                    //selectedOption={patient.user ? patient.user : '-99'}
                                    label={'first_name'}
                                    value={'id'} />
                            </Col>}
                            <Col xs="4" sm="2" md="2" lg="2" xl="2">
                                <Link
                                    className="c-subheader-nav-link card-list-header"
                                    aria-current="page"
                                    to="/patient/create"
                                    style={{ float: 'right' }}>
                                    <FontAwesomeIcon icon={faPlus} />&nbsp;New patient
                                </Link>
                            </Col>

                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {tempList.data.length > 0 &&
                            <Row>
                                {tempList?.data[page - 1]?.map((item, index) => (
                                    <Col key={index + 1} xs="12" sm="12" md="12" lg="4" xl="4">
                                        <Card style={{ marginBottom: '5px' }} border={'info'} className={'accent-info'}>
                                            <Card.Header>
                                                <Row>
                                                    <Col>
                                                        <Card.Title>{item?.first_name + ' ' + item?.last_name}</Card.Title>
                                                    </Col>
                                                    <Col align='right'>
                                                        <CButtonGroup>
                                                            <CButton
                                                                variant={'outline'}
                                                                color={appConstants.SUBMIT_BTN_COLOR}
                                                                size="sm"
                                                                title='Edit patient'
                                                                onClick={() => { editPatient(item) }}
                                                            ><FontAwesomeIcon icon={faPencilAlt} /></CButton>
                                                            <CButton
                                                                variant={'outline'}
                                                                color={appConstants.SAVE_BTN_COLOR}
                                                                size="sm"
                                                                title='Assessment list'
                                                                onClick={() => { gotoAssessmentList(item) }}
                                                            >Assmnt. list</CButton>
                                                            {state?.loggedUser?.role_code === UsermanagementConfigs.ROLE_CODES.administrator_role_code &&
                                                                <CButton
                                                                    variant={'outline'}
                                                                    color={appConstants.DELETE_BTN_COLOR}
                                                                    size="sm"
                                                                    title='Delete Patient Data'
                                                                    onClick={() => { confirmAndDeletePatientData(item) }}
                                                                ><FontAwesomeIcon icon={faTrash} /></CButton>
                                                            }
                                                        </CButtonGroup>
                                                    </Col>
                                                </Row>
                                            </Card.Header>
                                            <Card.Body>
                                                <Row>
                                                    <Col xs='12' md='12' lg='12'>
                                                        {item?.phone_number &&
                                                            <FontAwesomeIcon icon={faPhone} />}
                                                        &nbsp;{item?.phone_number ? item?.phone_number : ''}
                                                    </Col>
                                                    <Col xs='12' md='12' lg='12'>
                                                        {item?.email &&
                                                            <FontAwesomeIcon icon={faEnvelope} />} &nbsp;{item?.email ? item?.email : ''}
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))
                                }
                                {console.log("templist", !tempList)}
                            </Row>
                        }
                        {tempList?.data?.length === 0 &&
                            <label>No Patient's Found</label>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <Stack spacing={2}>
                            <Pagination count={(tempList.data.length)} page={page} onChange={handleChange} />
                        </Stack>
                    </Card.Footer>
                </Card>
            </Container>

        </>

    );
}

export default PatientList;