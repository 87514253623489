import React from 'react';

const MandatoryIndicator = (props) => {
    return (
        <>
            <span>{props.title}</span>
            <span className="text-danger">
                &nbsp;*
            </span>
        </>
    )
}

export default MandatoryIndicator