import { useContext } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import GlobalState from '../../shared/GlobalState';
import * as momentServices from '../../shared/MomentService';
import * as DateConfigs from '../../shared/configs/DateConfigs'
const TheHeaderPatientInfo = () => {
    const [state, setState] = useContext(GlobalState);
    return (
        <Container>

            <Card style={{ backgroundColor: '#ebf5ff' }}>
                <Card.Header>
                    <Row>
                        <Col>
                            Patient name: <b>{state?.patient?.first_name + ' ' + state?.patient?.last_name}</b>
                        </Col>
                        <Col>
                            Identification number: <b>{state?.patient?.identifier_number}</b>
                        </Col>
                    </Row>
                    {state?.assessmentDate &&
                        <Row>
                            <Col>
                                Date of assessment: <b>{momentServices.formatDate(state?.assessmentDate, DateConfigs.DEFAULT_DATE_FORMAT_UI)}</b>
                            </Col>
                        </Row>
                    }
                </Card.Header>
            </Card>
        </Container>
    )
}
export default TheHeaderPatientInfo