import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as AppConstants from './AppConstants';
import ReactHookTimer from './components/form-components/ReactHookTimer';

const IdleTimeOutModal = ({ showModal, handleClose, handleLogout, remainingTime }) => {
    const time = new Date();
    if (showModal) {
        time.setSeconds(time.getSeconds() + (remainingTime * 60));
    }
    return (
        <Modal show={showModal} onHide={() => { handleClose() }}>
            <Modal.Header
            // closeButton
            >
                <Modal.Title>Your session is about to Expire!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>For Security reasons, sessions that are inactive for more than {AppConstants.APP_INACTIVE_INTERVAL + AppConstants.APP_INACTIVE_AUTO_LOGOUT_INTERVAL} minutes will be automatically closed.</h4>
                <h5>
                    <ReactHookTimer
                        preFixText={"Your session will close in "}
                        postFixText={" seconds"}
                        expiryTimestamp={time} />
                </h5>
                <h6>
                    Click <b><i>Stay</i></b> to continue your session.
                </h6>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => { handleLogout() }}>
                    Logout
                </Button>
                <Button variant="primary" onClick={() => { handleClose() }}>
                    Stay
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default IdleTimeOutModal;