import axios from 'axios';
import * as appConstants from './../../AppConstants';
// import * as log from './../../logger';
import Toaster from './../../components/form-components/Toaster';
import { toast } from 'react-toastify';
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.


const customAxios = axios.create();

// Step-2: Create request, response & error handlers
const requestHandler = request => {
    // Token will be dynamic so we can use any app-specific way to always   
    // fetch the new token before making the call
    // request.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs';
    return request;
};

const responseHandler = response => {
    return response;
};

const getToaster = () => {
    return (<Toaster />);
}

const errorHandler = error => {
    getToaster();
    if (!error.response || !error.request) {
        toast.error(appConstants.SERVICE_UNAVAILABLE_503_MSG);
    }
    else {
        // log.error(error, sharedService.getStateInfo())
        if (error.response?.status === 401) {
            alert(appConstants.SessionLogoutMsg)
            window.location = appConstants.SessionLogoutURL;
        }
        if (error.request?.status === 503 || error.response?.status === 503) {
            toast.error(appConstants.SERVICE_UNAVAILABLE_503_MSG);
        }
    }
    return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
    function (request) {
        // console.log('Error while sending Request => ', request)
        return requestHandler(request)
    },
    function (error) {
        return errorHandler(error)
    }
);

customAxios.interceptors.response.use(
    function (response) {
        // console.log('Error while gettting response => ', response)
        return responseHandler(response)
    },
    function (error, data) {
        return errorHandler(error)
    }
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;