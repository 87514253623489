// export const devBaseURL = "http://192.168.1.33:5000";
export const devBaseURL = "http://localhost:5000";
// export const prodBaseURL = "https://prs_directory_services.nimhans.ac.in"; //PSYSOFT PRODUCTION server url
export const prodBaseURL = "https://ieat.vmsnimhans.in"; // SUMMIT office system URL
// export const prodBaseURL = "http://192.168.1.9:5000"; //Local ip
export const apiPostFix = 'api';
export const version = 'v1';

// media url in development mode
export const devMediaURL = "https://duqhpli3rdg2m.cloudfront.net"
// media url in production mode
export const prodMediaURL = "https://duqhpli3rdg2m.cloudfront.net"





