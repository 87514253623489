import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MandatoryIndicator from './MandatoryIndicator';
import { CFormText } from '@coreui/react';
import * as appConstants from '../../AppConstants';

const SelectBox = (props) => (
    <div>
        <FormControl variant={(props.variant) ? (props.variant) : appConstants.INPUT_OUTLINED_LABEL.variant}
            style={{ width: '100%' }}>
            <InputLabel htmlFor={props.name}>
                {props.title}
                <span className="text-danger">
                    {props.manadatorySymbol ? ' *' : ''}
                </span>
            </InputLabel>
            <Select
                native
                id={(props.variantId) ? (props.variantId) : appConstants.INPUT_OUTLINED_LABEL.id}
                variant={(props.variant) ? (props.variant) : appConstants.INPUT_OUTLINED_LABEL.variant}
                name={props.name}
                value={props.selectedOption}
                onChange={props.controlFunc}
                disabled={(props.disabled) ? true : false}
            >
                <option aria-label="None" value=""></option>
                {props.options.map((opt, index) => {
                    return (
                        <option
                            key={index}
                            value={opt[props.value]}
                        >
                            {opt[props.label]} {props.label2 ? opt[props.label2] : ''}
                        </option>
                    );
                })};
            </Select>
            {props.helpText && <CFormText>{props.helpText}</CFormText>}
        </FormControl>
    </div>
);
export default SelectBox;