import axios from './api-configs/axios-config';
import environment from '../environments/environment';
const PATIENT_ASSESSMENT_API_BASE_URL = environment.apiUrl + "/patient_assessment";
const PATIENT_INDIVIDUAL_SCORE_API_BASE_URL = environment.apiUrl + "/patient_individual_video_score"
const VIDEO_API_BASE_URL = environment.apiUrl + "/video"
const PATIENT_SCORE_API_BASE_URL = environment.apiUrl + "/patient_score"

class PatientService {
    createPatientAssessment(assessmentObj, authHeader) {
        return axios.post(PATIENT_ASSESSMENT_API_BASE_URL, assessmentObj, authHeader)
    }

    createPatientIndividualScore(patientScoreObj, authHeader) {
        return axios.post(PATIENT_INDIVIDUAL_SCORE_API_BASE_URL, patientScoreObj, authHeader)
    }

    getPatientIndividualVideoScore(assessment_id, video_code, authHeader) {
        return axios.get(PATIENT_INDIVIDUAL_SCORE_API_BASE_URL + '/' + assessment_id + '/' + video_code, authHeader)
    }

    getAllVideosBySets(assessment_id, authHeader, set_code = "all") {
        return axios.get(VIDEO_API_BASE_URL + '/group-by-videoset' + '/' + assessment_id + '/' + set_code, authHeader)
    }

    getVideosBySetCode(set_code, assessment_id, authHeader) {
        return axios.get(VIDEO_API_BASE_URL + '/' + set_code + '/' + assessment_id, authHeader)
    }

    updatePatientAssessment(assessment_id, assessmentObj, authHeader) {
        return axios.put(PATIENT_ASSESSMENT_API_BASE_URL + '/' + assessment_id, assessmentObj, authHeader)
    }

    getPatientAssessmentById(assessment_id, authHeader) {
        return axios.get(PATIENT_ASSESSMENT_API_BASE_URL + '/' + assessment_id, authHeader)
    }


    getPatientAssessmentsByPatientId(patient_id, authHeader) {
        return axios.get(PATIENT_ASSESSMENT_API_BASE_URL + '/' + 'get-by-patient/' + patient_id, authHeader)
    }

    deletePatientScoreById(patient_score_id, authHeader) {
        return axios.delete(PATIENT_SCORE_API_BASE_URL + '/delete/' + patient_score_id, authHeader)
    }
    getPatientRawVideoScores(patient_id, assessment_id, authHeader) {
        return axios.get(PATIENT_INDIVIDUAL_SCORE_API_BASE_URL + '/export-patient-raw-score/' + patient_id + '/' + assessment_id, authHeader)
    }
    deleteAssessmentById(assessment_id, patient_id, authHeader) {
        return axios.delete(PATIENT_ASSESSMENT_API_BASE_URL + '/delete/' + assessment_id + '/' + patient_id, authHeader)
    }
}
export default new PatientService();