import React from "react";
import InputLabel from '@material-ui/core/InputLabel';

const RadioButtonList = (props) => {
    return (
        <>
            <InputLabel htmlFor={props.name} style={{ marginBottom: '6px' }}>
                {props.title}
                <span className="text-danger">
                    {props.manadatorySymbol ? ' *' : ''}
                </span>
            </InputLabel>
            {props.options.map(opt => {
                return (
                    <label key={opt.id + '_' + opt.label} variant="custom-radio" inline="true">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            name={props.name}
                            onChange={props.controlFunc}
                            value={opt.value}
                            checked={props.content === opt.value}
                            type="radio"
                            disabled={(props.disabled) ? true : false}
                        /> &nbsp;&nbsp;{opt.label}
                    </label>
                )
            })}
        </>
    );
}

export default RadioButtonList;