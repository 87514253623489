import React from 'react';
import MandatoryIndicator from './MandatoryIndicator';
import * as appConstants from './../../AppConstants';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const PasswordInput = (props) => {
    const [values, setValues] = React.useState({});

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <FormControl variant={props.variant ? props.variant : appConstants.INPUT_OUTLINED_LABEL.variant}
            style={{ width: '100%' }}>
            <InputLabel htmlFor={props.variant ? props.variant + "-adornment-password" : appConstants.INPUT_OUTLINED_LABEL + "-adornment-password"}>
                {(props.manadatorySymbol) ? (<MandatoryIndicator title={props.title} />) : props.title}
            </InputLabel>
            <OutlinedInput
                id={props.variant ? props.variant + '-adornment-password' : appConstants.INPUT_OUTLINED_LABEL.variant + '-adornment-password'}
                type={values.showPassword ? 'text' : 'password'}
                name={props.name}
                value={props.content}
                onChange={(e) => {
                    props.controlFunc(e);
                    if (props?.passwordChangeFunc)
                        props.passwordChangeFunc(e)
                }}
                placeholder={props.placeholder}
                disabled={(props.disabled) ? true : false}
                className={props.class ? props.calss : ''}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>
    )
}

export default PasswordInput;