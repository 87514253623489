import { useEffect, useState, useRef } from "react";
import UsersService from '../../api/UsersService';
import DateRangePicker from "../../components/form-components/DateRangePicker";
import * as momentServices from "../../shared/MomentService";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Modal } from 'react-bootstrap';
import { CButton } from '@coreui/react';
import SingleTextInput from "../../components/form-components/SingleTextInput";
import * as appConstants from '../../AppConstants';
import SelectBox from "../../components/form-components/SelectBox";
import PatientService from "../../api/PatientService";
import DownloadToCsv from "../../components/form-components/DownloadToCsv";
import { toast } from "react-toastify";
import * as UserManagementConfigs from '../../shared/UserManagementConfig';
const PatientDataExport = ({ state, showExportFilterModal, closeExportFilterModal }) => {
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState({})
    const [selectedDateRange, setSelectedDateRange] = useState([{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }])
    const [patientsScore, setPatientsScore] = useState({ data: [] })
    const [showPickerModal, setShowPickerModal] = useState(false)
    const [download, setDownload] = useState(false);

    useEffect(() => {
        getAllUsers()
    }, [])

    const getAllUsers = () => {
        UsersService.getAllUsersByStatus("Active", state?.authHeader).then(resp => {
            let list = [] = resp?.data;
            list.unshift({ first_name: 'All', id: 0 })
            setUserList(list)
            if (state?.loggedUser?.role_code !== UserManagementConfigs.ROLE_CODES.administrator_role_code) {
                filters.user = state?.loggedUser?.id;
            }
        })
    }
    const exportPatientScores = () => {
        setDownload(!download)
        PatientService.getAllPatientsData(filters?.start_date, filters?.end_date, filters?.user, state?.authHeader).then(resp => {
            if (resp?.data?.length > 0) {
                patientsScore.data = resp?.data
                setPatientsScore({ ...patientsScore, data: resp?.data })
                setDownload(true)
                toast.success("Patients score data downloaded successfully")
            } else {
                toast.info("No patient data found for the given filters")
            }

        })
    }
    const closeDateRangePickerModal = () => {
        setShowPickerModal(false)
    }

    const dateRangePickerChangeHandler = (ranges) => {
        setSelectedDateRange([ranges.selection]);
        setFilters({ ...filters, 'start_date': momentServices.formatDatetimeToISOString(ranges.selection.startDate), 'end_date': momentServices.formatDatetimeToISOString(ranges.selection.endDate) });
    }
    const handleChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value })
    }

    return (
        <>
            <Modal
                show={showExportFilterModal}
                onHide={closeExportFilterModal}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    Export patient scores
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm='12' md='11' lg='11'>
                            <SingleTextInput
                                name="date"
                                title="Date Range"
                                inputType="text"
                                content={(filters?.start_date && filters?.end_date) ? momentServices.formatDate(filters?.start_date) + ' to ' + momentServices.formatDate(filters?.end_date) : ''}
                                variant={appConstants.INPUT_OUTLINED_LABEL.variant}
                                disabled={true}
                            />
                        </Col>
                        <Col sm='12' md='1' lg='1'>
                            <CButton
                                type="button" color="info"
                                style={{ marginTop: '7px', marginLeft: '-25px', cursor: 'pointer' }}
                                onClick={() => { setShowPickerModal(true) }} >
                                <FontAwesomeIcon icon={faCalendar} size="lg" />
                            </CButton>
                            <DateRangePicker
                                handleChangeFunc={dateRangePickerChangeHandler}
                                showDateRangePickerModal={showPickerModal}
                                closeDateRangePickerModal={closeDateRangePickerModal}
                                daterange={selectedDateRange}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SelectBox
                                name="user"
                                selectedOption={filters?.user ? filters?.user : ''}
                                options={userList}
                                controlFunc={handleChange}
                                label='first_name'
                                label2='last_name'
                                value='id'
                                title="Users"
                                disabled={state?.loggedUser?.role_code !== UserManagementConfigs.ROLE_CODES.administrator_role_code}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col>
                            <CButton color={appConstants.EDIT_BTN_COLOR} onClick={exportPatientScores}>
                                Download
                            </CButton>
                            {(download && patientsScore?.data?.length > 0) &&
                                <DownloadToCsv
                                    data={patientsScore?.data}
                                    headers={appConstants?.export_headers}
                                    fileName={appConstants?.ALL_PATIENT_SCORE_EXPORT_FILE_FORMAT}
                                />
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default PatientDataExport;