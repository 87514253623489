export const PICKER_DEFAULT_DATE = "1900-01-01";

// Date Format for Frontend
export const DEFAULT_DATE_FORMAT_UI = "DD-MMM-YYYY";
// Time Format for Frontend
export const DEFAULT_TIME_FORMAT_UI = "h:mm";

// Date Format for Backend
export const DEFAULT_DATE_FORMAT_BACKEND = "Y-MM-DD";
// Time Format for Backend
export const DEFAULT_TIME_FORMAT_BACKEND = "h:mm:ss";

// Date and Time Format for Backend
export const DEFAULT_DATE_TIME_FORMAT_BACKEND = DEFAULT_DATE_FORMAT_BACKEND + " " + DEFAULT_TIME_FORMAT_BACKEND;

// Date and Time Format for Frontend
export const DEFAULT_DATE_TIME_FORMAT_FRONTEND = DEFAULT_DATE_FORMAT_UI + " " + DEFAULT_TIME_FORMAT_UI;