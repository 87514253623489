import React, { useState, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer';
import IdleTimeOutModal from './IdleTimeoutModal';
import GlobalState from './shared/GlobalState';
import LoginService from './api/LoginService';
import * as AppConstants from './AppConstants';
import { Route, Routes, useNavigate } from "react-router-dom";
import CreateUser from './views/app-mgt/users/Create';
import UsersList from './views/app-mgt/users/List';
import ChangePassword from './ChangePassword';
import CreatePatient from './views/patient/Create';
import PatientList from './views/patient/List';
import PatientAssessmentList from './views/patient-assessment/PatientAssessmentList';
import VideoAssessmentList from './views/patient-assessment/VideoAssessmentList';
import VideoAssessment from './views/patient-assessment/VideoAssessment';
import ResearchAndAllocation from './views/app-mgt/ResearchAndAllocation';

const AppPages = () => {
    const navigate = useNavigate();
    const [state, setState] = useContext(GlobalState);
    const [showIdleTimeoutModal, setShowIdleTimeoutModal] = useState(false);
    const [sessionInterval, setSessionInterval] = useState({ "time": AppConstants.APP_INACTIVE_INTERVAL })
    const [isIdle, setIsIdle] = useState(false);

    const handleOnIdle = event => {
        if (!isIdle) {
            setIsIdle(true);
            sessionInterval.time = AppConstants.APP_INACTIVE_AUTO_LOGOUT_INTERVAL;
            setSessionInterval({ "time": AppConstants.APP_INACTIVE_AUTO_LOGOUT_INTERVAL });
            setShowIdleTimeoutModal(true);
        }
        else {
            handleLogout();
        }
    }

    const handleOnActive = event => {
        // console.log('user is active', event);
        // console.log('time remaining', getRemainingTime());
    }

    const handleOnAction = (e) => {
        // console.log('user did something', e);
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * sessionInterval.time,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    });

    const handleLogout = () => {
        setShowIdleTimeoutModal(false);
        console.log('logout is calling')
        LoginService.logout(state.authHeader).then(res => {
            setState(state => ({}));
            navigate('/')
            console.log('logged out')
        });
    }

    const handleClose = () => {
        setShowIdleTimeoutModal(false);
        setIsIdle(false);
        setSessionInterval({ "time": AppConstants.APP_INACTIVE_INTERVAL });
    }

    return (
        <>
            <IdleTimeOutModal
                showModal={showIdleTimeoutModal}
                handleClose={handleClose}
                handleLogout={handleLogout}
                remainingTime={AppConstants.APP_INACTIVE_AUTO_LOGOUT_INTERVAL} />
            <Routes>
                <Route path="/users/create" element={<CreateUser />} />
                <Route path="/users/update/:user_id" element={<CreateUser />} />
                <Route path="/users/list" element={<UsersList />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/patient/create" element={<CreatePatient />} />
                <Route path="/patient/update/:patient_id" element={<CreatePatient />} />
                <Route path="/patient/list" element={<PatientList />} />
                <Route path="/patient-assessment/list/:patient_id" element={<PatientAssessmentList />} />
                <Route path="/patient-video-assessment/list/:patient_id/:assessment_id" element={<VideoAssessmentList />} />
                <Route path="/patient-video-assessment/:patient_id/:assessment_id" element={<VideoAssessment />} />
                <Route path="/user/research-allocation" element={<ResearchAndAllocation />} />
            </Routes>
        </>
    )
}
export default AppPages;