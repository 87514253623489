import React, { useContext, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import nimhansLogo from "./nimhans-logo-removebg-preview.png";
import "./Navbar.css";
import { Link, Outlet } from "react-router-dom";
import GlobalState from './../../shared/GlobalState';
import { useNavigate } from "react-router-dom";
import LoginService from './../../api/LoginService';
import * as appConstants from './../../AppConstants';
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CButton } from '@coreui/react';
import { CSVDownload, CSVLink } from "react-csv";
import { Modal, Row, Col } from 'react-bootstrap'
import Toaster from '../form-components/Toaster';
import { toast } from 'react-toastify';
import SingleTextInput from "../form-components/SingleTextInput";
import PatientService from "../../api/PatientService";
import { faBullseye, faDoorOpen, faTruckField } from "@fortawesome/free-solid-svg-icons";
import * as UserManagementConfigs from '../../shared/UserManagementConfig';
import PasswordInput from "../form-components/PasswordInput";
import DateRangePicker from "../form-components/DateRangePicker";
import * as momentServices from "../../shared/MomentService";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PatientDataExport from "../../views/patient/PatientDataExport";

const Navbar = () => {
	const navigate = useNavigate();
	const [state, setState] = useContext(GlobalState);
	const [openPasswordModal, setOpenPasswordModal] = useState(false)
	const [user, setUser] = useState({})
	const [isValidDownload, setIsValidDownload] = useState(false)
	const [patientsData, setPatientsData] = useState([])
	const [filters, setFilters] = useState({})
	const [showExportFilterModal, setShowExportFilterModal] = useState(false)
	const [showDateRangePickerModal, setShowDateRangePickerModal] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState([{
		startDate: new Date(),
		endDate: null,
		key: 'selection'
	}])

	const exportPatientData = () => {
		console.log("exporting patient data")
	}

	const handleChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value })
	}

	const closePasswordModal = (showsuccessMessage = false) => {
		setOpenPasswordModal(false)
		setIsValidDownload(false)
		setUser({})
		if (showsuccessMessage)
			toast.success("patients report downloaded successfully")
	}

	const validateCurrentPasswordAndExport = async (e) => {
		const md5 = require('md5')
		let Obj = JSON.parse(JSON.stringify(user))
		// password encryption

		Obj.username = state?.loggedUser?.username;
		Obj.password = md5(Obj.password)
		await LoginService.checkEnteredPasswordIsCorrectOrNot(Obj, state?.authHeader).then(async res => {
			if (res?.data?.code === 200) {
				// export data to csv
				closePasswordModal();
				setShowExportFilterModal(true)

				// await PatientService.getAllPatientsData(state?.authHeader).then(resp => {
				// 	console.log("All patients data", resp?.data)
				// 	setPatientsData(resp?.data)
				// 	setIsValidDownload(true)
				// })
			}
			else if (res.data && res.data.code === 401) {
				setIsValidDownload(false)
				toast.error('Incorrect Password');
			}
			else if (res.data && !(res.data.code === 401)) {
				setIsValidDownload(false)
				console.log("code is 200")
			}
		}).catch(error => {
			console.log(error)
			setIsValidDownload(false)
			toast.error('The entered password is incorrect');
		});
	}

	const closeDateRangePickerModal = () => {
		setShowDateRangePickerModal(false)
	}

	const dateRangePickerChangeHandler = (ranges) => {
		setSelectedDateRange([ranges.selection]);
		setFilters({ ...filters, 'start_date': momentServices.formatDatetimeToISOString(ranges.selection.startDate), 'end_date': momentServices.formatDatetimeToISOString(ranges.selection.endDate) });
	}

	const closeExportFilterModal = () => {
		console.log("in close export filter modal")
		setShowExportFilterModal(false)
		setSelectedDateRange([{
			startDate: new Date(),
			endDate: null,
			key: 'selection'
		}])
		setFilters({})
	}

	const exportFiltersModal = () => {
		return (
			<>
				{showExportFilterModal &&
					< PatientDataExport
						state={state}
						showExportFilterModal={showExportFilterModal}
						closeExportFilterModal={closeExportFilterModal}
					/>
				}
			</>
		)
	}

	const passwordModal = () => {
		return (
			<Modal
				show={openPasswordModal}
				onHide={closePasswordModal}
				backdrop="static"
				keyboard={false}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>

				<Modal.Header closeButton>
					Password verification
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col>
							<PasswordInput name="password"
								title="Password"
								content={(user.password) ? (user.password) : ''}
								manadatorySymbol={true}
								controlFunc={handleChange} />
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Row>
						<Col align='right'>
							{isValidDownload === false &&
								<CButton
									onClick={validateCurrentPasswordAndExport}
								>
									Verify
								</CButton>
							}
							{/* {isValidDownload &&
								<CSVLink data={patientsData} headers={appConstants.export_headers}>
									<CButton color={appConstants.EDIT_BTN_COLOR} onClick={closePasswordModal}>
										Download
									</CButton>
								</CSVLink>
							} */}
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		)
	}

	return (
		<>
			{
				passwordModal()
			}
			{
				exportFiltersModal()
			}
			<Toaster />
			<div className=".nav1">
				<nav className="navbar navbar-expand-sm navbar-light">
					<div className="container-sm">
						<div className="navbar-brand">
							<img src={nimhansLogo} alt="" className="header-logo" />
							<label>Indian Empathy Assessment Tool</label>
						</div>

						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
							style={{ align: 'right' }}
						>
							<MenuIcon style={{ color: "#fff", fontSize: "30px" }} />
						</button>
						{state?.isAuthenticated &&
							<div
								className="collapse navbar-collapse justify-content-end"
								id="navbarNav"
							>
								<ul className="navbar-nav ml-auto" style={{ alignItems: 'center' }}>
									<li className="nav-item" style={{ paddingTop: "3px" }}>
										<Link to="/patient/list">Patients</Link>
									</li>
									<li className="nav-item" style={{ paddingTop: "3px" }}>
										<Link to="/users/list">Users</Link>
									</li>
									{/* {state?.loggedUser?.role_code === UserManagementConfigs.ROLE_CODES.administrator_role_code && */}
									<li className="nav-item" style={{ paddingTop: "3px" }}>
										<CButton
											size="sm"
											onClick={() => setOpenPasswordModal(true)}
											title="Exports patients scores"
										>
											Export patients scores
										</CButton>
									</li>
									{/* } */}
									<li className="nav-item" style={{ paddingTop: "3px" }}>
										{!state.isAuthenticated &&
											<Link to="/login">LOGIN</Link>
										}
									</li>
									{state.isAuthenticated &&
										<li className="nav-item" style={{ marginLeft: '-25px' }}>
											<CDropdown>
												<CDropdownToggle
													color="secondary"
													style={{ borderRadius: '25px', fontWeight: 800, marginTop: '-4px' }}>
													{state?.loggedUser?.username} &nbsp;
												</CDropdownToggle>
												<CDropdownMenu>
													{state?.loggedUser?.role_code === UserManagementConfigs.ROLE_CODES.administrator_role_code &&
														<>
															<CDropdownItem component="button" onClick={() => {
																navigate('/user/research-allocation')
															}} >Research and PI</CDropdownItem>
															<CDropdownDivider />
														</>
													}
													<CDropdownItem component="button" onClick={() => {
														navigate('/users/update/' + state?.loggedUser?.id)
													}} >View / Update Profile</CDropdownItem>
													<CDropdownDivider />
													<CDropdownItem component="button" onClick={() => {
														navigate("/change-Password");
													}} >Change Password</CDropdownItem>
													<CDropdownDivider />
													<CDropdownItem component="button" onClick={() => {
														LoginService.logout(state.authHeader).then(res => {
															setState(state => ({}));
															navigate('/')
														});
													}} >Logout</CDropdownItem>
												</CDropdownMenu>
											</CDropdown>
										</li>}
								</ul>
							</div>
						}
					</div>
				</nav >
			</div >
			< Outlet />
		</>
	);
};

export default Navbar;