import React from 'react';
import { useTimer } from 'react-timer-hook';

const ReactHookTimer = ({ preFixText, expiryTimestamp, postFixText }) => {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp, onExpire: () => {
            console.warn('onExpire called');
        }
    });
    console.log(expiryTimestamp)


    return (
        <div>{/*  style={{ textAlign: 'center' }} */}
            {/* <h1>react-timer-hook </h1>
            <p>Timer Demo</p> */}
            <div>
                {/*  style={{ fontSize: '100px' }} */}
                {/* <span>{days}</span>
                :
                <span>{hours}</span>
                :
                <span>{minutes}</span>
                : */}
                <span>{preFixText}</span>
                <span><strong>{(minutes === 1) ? <span>{minutes} minute </span> : (minutes >= 1) ? <span>{minutes} minutes</span> : ''}
                    {seconds}</strong></span>
                <span>{postFixText}</span>
            </div>
            {/* <p>{isRunning ? 'Running' : 'Not running'}</p>
            <button onClick={start}>Start</button>
            <button onClick={pause}>Pause</button>
            <button onClick={resume}>Resume</button>
            <button onClick={() => {
                // Restarts to 5 minutes timer
                const time = new Date();
                time.setSeconds(time.getSeconds() + 300);
                restart(time)
            }}>Restart</button> */}
        </div>
    );
}

export default ReactHookTimer;