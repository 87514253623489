import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";
// import SearchResult from "./components/navbar components/SearchResult";
import GlobalState from './shared/GlobalState';

import AppPages from './AppPages';
import NonAuthpages from './NonAuthpages';
import './scss/styles.css'
import './App.scss'
import Navbar from "./components/header/Navbar";
import TheHeaderPatientInfo from "./components/header/TheHeaderPatientInfo";
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
)

const App = () => {
	const [state, setState] = useState({});
	console.log(state)
	return (
		<>
			<GlobalState.Provider value={[state, setState]}>
				<BrowserRouter basename="/">
					<Navbar />

					{state?.patient?.id &&
						<TheHeaderPatientInfo />
					}
					<br />
					<React.Suspense fallback={loading}>
						<NonAuthpages state={state} />
						{state.isAuthenticated &&
							<AppPages state={state} />
						}
					</React.Suspense>
					<Footer />
				</BrowserRouter>
			</GlobalState.Provider>
		</>
	);
};

export default App;
