import * as envConfigs from './environment-config';

const envMode = (process.env.NODE_ENV === 'development') ? true : false;
let apiBasePath = '', mediaBasePath = '';
if (envMode) {
    apiBasePath = envConfigs.devBaseURL; //"localhost:5000"
    mediaBasePath = envConfigs.devMediaURL;
}
else {
    apiBasePath = envConfigs.prodBaseURL; //'http://10.11.3.204';
    mediaBasePath = envConfigs.prodMediaURL;
}

const environment = {
    mode: envMode,
    appUrl: 'http://localhost:3000/',
    apiUrl: (apiBasePath + '/' + envConfigs.apiPostFix + '/' + envConfigs.version),
    siteKey: '',
    secretKey: '',
    mediaUrl: mediaBasePath
};

export default environment;