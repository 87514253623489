import _ from 'lodash';
import * as MomentService from './MomentService';

export var tempState;

export const getAuthHeaders = (props) => {
    return { 'headers': { 'Authentication-Token': props } };
}

export const CDataTableToggleDetails = (index, details) => {
    if (details.indexOf(index) !== -1)
        details = -1;
    else
        details = index;
    return details;
};

export const calculateScore = (fieldList, obj) => {
    let totalScore = 0;
    fieldList.forEach(element => {
        if (obj[element.name]) {
            totalScore += parseFloat(obj[element.name]);
        }
    });
    return totalScore;
}

export const setGlobalState = (state, obj) => {
    tempState = {
        ...state,
        patient: obj?.patient,
        visitId: obj?.visitId,
        recordType: obj?.recordType,
        proformaName: obj?.proformaName,
        proformaValue: obj?.proformaValue,
        leftNavMenuItems: obj?.leftNavMenuItems,
        prevNextPagesStack: obj?.prevNextPagesStack,
        currentDate: obj?.currentDate,
        unit: obj?.unit,
        module: obj?.module,
        visitDate: obj?.visitDate,
        proforma_unit: obj?.proforma_unit,
        unitName: obj?.unitName,
        unitFullName: obj?.unitFullName,
        blockName: obj?.blockName,
        roomName: obj?.roomName,
        is_finished: obj.is_finished,
        is_appointment_not_required: obj.is_appointment_not_required
    };
    return tempState;
}

export const getStateObject = (state, pvObj, result) => {
    return {
        unit: state.unit,
        module: state.module,
        patient: state.patient,
        visitId: pvObj.id,
        currentDate: state.currentDate,
        recordType: pvObj.proforma_type,
        proformaName: pvObj.proforma_name,
        proformaValue: pvObj.proforma_value,
        leftNavMenuItems: result.leftNavMenuItems,
        prevNextPagesStack: result.prevNextPagesStack,
        visitDate: MomentService.formatDate(pvObj.visit_date),
        proforma_unit: pvObj.unit,
        unitName: state.unitName,
        unitFullName: state.unitFullName,
        blockName: state.blockName,
        roomName: state.roomName,
        is_finished: pvObj.is_finished,
        is_appointment_not_required: pvObj.is_appointment_not_required
    };
}

// takes objects of list as input and converts object array into dictionary
export const convertObjectArrayIntoDictionary = (data, fieldName) => {
    // example [{'id':1, 'name':'abc'}, {'id':2, 'name':'def'}, {'id':3, 'name':'def'}] 
    // => {'abc' : {'id':1, 'name':'abc'}, 'def' : {'id':1, 'name':'def'}} 
    // and lso removes duplicates from list
    let newList = _.keyBy(data, fieldName)

    // get only keys from the key value list
    return _.keys(newList)

    // _.values(datalist) to get only values from key value list
}

export const getValuesFromArrayOfObjectsByAttribute = (dataList, keyName) => {
    //     var users = [
    //         { 'user': 'barney' },
    //         { 'user': 'fred' }
    //     ];

    //     // using the `_.property` callback shorthand
    //     _.map(users, 'user');
    // // => ['barney', 'fred']
    return _.map(dataList, keyName)
}

// takes  obects of array as input and return obects of array with now duplicates
// where data should list of objects
export const removeDuplicates = (data, fieldName) => {
    let uniqueData = _.uniqBy(data, fieldName);
    return _.reject(uniqueData, [fieldName, null]);
}

export const sortList = (data, fieldName) => {
    return _.sortBy(data, fieldName);
}

export const partitionList = (data, fieldName, value) => {
    return _.partition(data, [fieldName, value]);
}

export const groupByKeyValue = (dataList, fieldaName) => {
    return _.groupBy(dataList, data => data[fieldaName]);
}

export const getKeys = (object) => {
    return _.keys(object);
}

export const filterList = (data, fieldName, value) => {
    return _.filter(data, [fieldName, value]);
}

export const getProformaName = (proformaTypeId, proformaTypeList, fieldName) => {
    let list = _.filter(proformaTypeList.data, ['id', parseInt(proformaTypeId)]);
    if (list.length > 0)
        return list[0][fieldName];
    else
        return '';
}

export const findIndexByAttributeValue = (list, fieldName, value) => {
    return _.findIndex(list, [fieldName, value]);
}

export const findByAttributeValue = (list, fieldName, value) => {
    return _.find(list, [fieldName, value]);
}

export const concateFiledByAttribute = (data, fieldName) => {
    let result = '';
    _.forEach(data, function (element) {
        if (element[fieldName])
            result += element[fieldName] + ', ';
    });
    return result;
}

export const setUnitObject = (unitObj) => {
    return {
        'id': unitObj.id,
        'code': unitObj.code,
        'name': unitObj.name,
        'full_name': unitObj.full_name,
    }
}

export const setBlockObject = (blockObj) => {
    return {
        'id': blockObj.id,
        'code': blockObj.code,
        'name': blockObj.name
    }
}
export const setDepartmentObject = (deptObj) => {
    return {
        'id': deptObj.id,
        'name': deptObj.name,
        'full_name': deptObj.full_name,
    }
}

export const setUserObject = (userObj) => {
    return {
        'id': userObj.id,
        'name': userObj.first_name + ' ' + userObj.last_name,
        'username': userObj.username
    }
}

export const getUserObject = (userObj) => {
    let name = userObj?.first_name ? userObj?.first_name : '';
    name += userObj?.middle_name ? ' ' + userObj?.middle_name : '';
    name += userObj?.last_name ? ' ' + userObj?.last_name : '';

    return {
        'id': userObj.id,
        'salutation': userObj?.salutation ? userObj?.salutation : '',
        'first_name': userObj?.first_name ? userObj?.first_name : '',
        'last_name': userObj?.last_name ? ' ' + userObj?.last_name : '',
        'middle_name': userObj?.middle_name ? ' ' + userObj?.middle_name : '',
        'name': name,
        'username': userObj?.username ? userObj?.username : '',
        'department': userObj?.department ? userObj?.department : '',
        'designation': userObj?.designation ? userObj?.designation : '',
        'med_reg_num': userObj?.med_reg_num ? userObj?.med_reg_num : ''
    }
}

export const checkHasAccessOrNot = (pvObj, roomId, roomObj = null) => {
    let temp = true;
    if (pvObj?.id === null && roomObj?.id && roomObj?.id === roomId)
        return false;
    if (!pvObj?.visit?.id && pvObj?.room?.id === roomId)
        return false;
    if ((pvObj?.to_be_seen?.room?.id === roomId) || (pvObj?.seeing?.room?.id === roomId)) {
        return false;
    }
    if (pvObj?.seen_doctors !== null && pvObj?.seen_doctors?.length > 0) {
        for (let i = 0; i < pvObj?.seen_doctors.length; i++) {
            let doctor = pvObj?.seen_doctors[i];
            if (doctor?.room?.id === roomId) {
                temp = false;
                return;
            }
        }
    }
    return temp;
}

export const getLoggedUserRoleCode = (roles, roleCode) => {
    let roleObj = roles?.find(role => role.role_code === roleCode)
    if (roleObj?.id)
        return true;
    return false;
}

export const getLoggedUserHasRoleCodes = (roles, roleCodes) => {
    let flag;
    for (let i = 0; i < roleCodes.length; i++) {
        if (getLoggedUserRoleCode(roles, roleCodes[i])) {
            flag = true;
            break;
        }
    }
    return flag;
}

export const orderedList = (data, fieldName, order) => {
    return _.orderBy(data, [list => list[fieldName].toLowerCase()], [order])
}

export const checkUserHasRoleAndPermission = (rolesList, role, permission) => {
    if (rolesList.length <= 0)
        return false;
    if (getLoggedUserRoleCode(rolesList, role)) {
        let roleObj = findByAttributeValue(rolesList, 'role_code', role);
        if (roleObj && roleObj?.id) {
            return checkUserHasPermission(roleObj.permissions, permission);
        }
    }
    return false;
}

export const checkUserHasPermission = (permissionList, permission) => {
    return permissionList?.includes(permission);
}

export const checkUserHasPermissions = (permissionList, userPermList) => {
    let flag;
    for (let i = 0; i < userPermList.length; i++) {
        if (checkUserHasPermission(permissionList, userPermList[i])) {
            flag = true;
            break;
        }
    }
    return flag;
}
// conversion of string to html
export const stringToHTML = (string) => {
    return (
        <div dangerouslySetInnerHTML={{ __html: string }}></div>
    )
}

// get file format for the uploaded file
export const getFileUploadFormat = (data, delimeter) => {
    let result = '';
    data.map((item) => {
        result += item + delimeter;
    })
    return result;
}

// Filter string in a list of objects with all the keys in all the rows
export const filterListObjectsByValue = (array, string, nestedKeys = []) => {
    if (array.length > 0 && nestedKeys.length > 0) {
        array.forEach((row, i) => {
            nestedKeys.forEach(element => {
                array[i][element.field_name] = row[element.key][element.field_name];
            });
        });
    }
    return array.filter(o => Object.keys(o).some(k => _.toLower(o[k]).includes(_.toLower(string))));
}

export const getStateInfo = (data = tempState) => {
    return {
        //unit:data.unitFullName,
        //name:data.loggedUser.first_name+' '+data.loggedUser.last_name,
        username: data.loggedUser.username,
        pagecode: data.currentPageCode,
        visitid: data.visitId,
        patientuhid: data?.patient?.uhid
    }
}

export const getColorByStatus = (status) => {
    let color = 'secondary';
    switch (status) {
        case 'DRAFT': color = 'secondary';
            break;
        case 'SUBMITTED': color = 'primary';
            break;
        case 'APPROVED':
        case 'ACTIVE': color = 'success';
            break;
        case 'REJECTED': color = 'danger';
            break;
        case 'IN-ACTIVE': color = 'dark';
            break;
    }
    return color;
}

export const getNumbers = (start, end) => {
    let result = [];
    for (let i = start; i <= end; i++) {
        result.push({ "label": i, "value": i });
    }
    return result;
}

// export const getDays = (id) => {
//     switch(id) {
//         case '1': ({ "label": 'Monday', "value": 'Monday' });
//         case '2': ({ "label": 'Tuesday', "value": 'Tuesday' });
//     }
// }
export const setRowstoPage = (dataList, tempList) => {
    if (tempList.length < dataList.length) {
        let initalLength = tempList.length;
        for (let i = initalLength; i < initalLength + 4; i++) {
            if (dataList[i]) {
                tempList.push(dataList[i]);
            }
        }
    }

    return tempList;
}

export const getPatientName = (data, delimeter) => {
    let fName = data?.first_name;
    let lName = data?.last_name;
    return fName + (delimeter ? delimeter : ' ') + lName;
}