import React, { useEffect, useState, Component } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import * as SharedService from './../../shared/SharedService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const MultiSelect = (props) => {
    const theme = useTheme();

    const getLabel = (obj) => {
        let label = obj[props.optionLabel];
        label += (obj[props.optionLabel2] ? ' ' + obj[props.optionLabel2] : '');
        label += (obj[props.optionLabel3] ? ' ' + obj[props.optionLabel3] : '');
        return label;
    }

    const getChipLabels = (value) => {
        let obj = SharedService.findByAttributeValue(props.options, 'id', value);
        return getLabel(obj);
    }

    return (
        <FormControl sx={{ m: 1 }} style={{ width: '100%' }}>
            <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
            <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                name={props.name}
                disabled={props?.disabled ? props?.disabled : false}
                value={props.selectedOption}
                onChange={props?.storeDataObj === true ? '' : props.controlFunc}
                input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={getChipLabels(value)} />
                        ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
                {props?.options.map((item, index) => (
                    <MenuItem
                        key={item.id + '_' + index}
                        value={
                            props?.storeDataObj === true ? item : item[props.optionValue]
                        }
                        style={getStyles(item[props.optionValue], props.selectedOption, theme)}
                        onClick={props?.storeDataObj === true ? () => props?.controlFunc(item) : ''}>
                        <Checkbox checked={props.selectedOption.indexOf(item[props.optionValue]) > -1} />
                        <ListItemText primary={getLabel(item)} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

}

export default MultiSelect;